import React, { Component } from 'react'
import {Link, Redirect } from 'react-router-dom'
import CarRow from '../../../components/Cars/CarRow'
import CarEditStatus from "../CarEditStatus/CarEditStatus"
import axios from 'axios'
import { APIURL, PAGE10 } from '../../../config/config'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import CarCalculate from '../../../components/Cars/CarCalculate'
import CarShare from '../../../components/Cars/CarShare'
import { connect } from 'react-redux'
import CarLeasingFormNew from '../CarLeasingFormNew'
import CarEditSellPrice from '../CarEditSellPrice'
import Loading from '../../Loading'
import { AlertError } from '../../Alert/Alert'
import { changeUserType, loginUserSuccess, logoutAndRedirect } from '../../../actions'
import { checkGroupPermission, getGroupPermission } from '../../../services/grouppermission'
import CarQuotation from "../CarQuotation/CarQuotation";
import { save } from 'save-file';
// import CarDepositRefundFormNew from "../CarDepositRefund/CarDepositRefundFormNew";

// function withParams(Component) {
//   return props => <Component {...props} params={useParams()} />;
// }

const port = Number(window.env.PORT);

class CarList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuID: [1, 18, 22, 23, 101,102,103,104, 105, 108, 26, 109],
      grouppermissions: [],
      lists: [],
      user: {},
      user_type: '',
      car_id: 0,
      action: 'บันทึก',
      car: '',
      car_edit_status: {},
      toggle_car_status_edit: false,
      toggle_car_calculate: false,
      toggle_car_share: false,
      car_calculate: {},
      car_share: {},
      count_car: 0,
      car_select: false,
      is_search: false,
      car_delete: {},
      toggle_car_status_delete: false,
      toggle_car_status_leasing: false,
      carLeasingList: [],
      page: 0,
      car_edit_sell_price: {},
      toggle_car_edit_sell_price: false,
      sum_cost_all: 0,
      sum_vat_buy: 0,
      sum_withholding: 0,
      isLoading: false,
      // search ตามเงื่อนไข
      branch: [],
      branch_id: '',
      car_status: [],
      car_status_id: '',
      car_structure: [],
      car_structure_id: '',
      car_brand: [],
      car_brand_id: '',
      order_by: '',
      orders: [
        {order_id: 'last', order_name: 'วันที่แก้ไขข้อมูลล่าสุด'},
        {order_id: 'price_low', order_name: 'ราคาขาย : ต่ำไปสูง'},
        {order_id: 'price_high', order_name: 'ราคาขาย : สูงไปต่ำ'},
        {order_id: 'name_asc', order_name: 'ทะเบียนรถ : ก-ฮ'},
        {order_id: 'name_desc', order_name: 'ทะเบียนรถ : ฮ-ก'},
        {order_id: 'car_in_desc', order_name: 'วันที่ซื้อรถเข้า : ใหม่ไปเก่า'},
        {order_id: 'car_in_asc', order_name: 'วันที่ซื้อรถเข้า : เก่าไปใหม่'},
        {order_id: 'year_desc', order_name: 'ปีรถ : ใหม่ไปเก่า'},
        {order_id: 'year_asc', order_name: 'ปีรถ : เก่าไปใหม่'},
        {order_id: 'car_miles_asc', order_name: 'เลขไมล์ : ต่ำไปสูง'},
        {order_id: 'car_miles_desc', order_name: 'เลขไมล์ : สูงไปต่ำ'},
      ],
      banks: [],
      liciense_status_id: '',
      width: 0,
      height: 0,
      //
      toggle_car_quotation: false,
      car_quotation: {},
    }

    this.getRenderTextCarSearch = this.getRenderTextCarSearch.bind(this)
    this.onCarStatusEdit = this.onCarStatusEdit.bind(this)
    this.toggleCarStatusEdit = this.toggleCarStatusEdit.bind(this)
    this.onCarStatusChange = this.onCarStatusChange.bind(this)
    this.toggleCarCalculate= this.toggleCarCalculate.bind(this);
    this.onCarCalculate = this.onCarCalculate.bind(this);
    this.onCarShare = this.onCarShare.bind(this);
    this.toggleCarShare = this.toggleCarShare.bind(this)
    this.onCarShareChange = this.onCarShareChange.bind(this)
    this.onRefreshCar = this.onRefreshCar.bind(this);
    this.toggleCarDelete = this.toggleCarDelete.bind(this);
    this.onCarDelete = this.onCarDelete.bind(this);
    this.carDelete = this.carDelete.bind(this);
    this.toggleCarLeasing = this.toggleCarLeasing.bind(this);
    this.onCarLeasing = this.onCarLeasing.bind(this);
    this.onSubmitCarLeasing = this.onSubmitCarLeasing.bind(this);
    this.searchByCarStructure = this.searchByCarStructure.bind(this);
    this.onCarExplainFormEdit = this.onCarExplainFormEdit.bind(this);
    this.onCarExplainUpdate = this.onCarExplainUpdate.bind(this);
    this.onCarEditSellPrice = this.onCarEditSellPrice.bind(this);
    this.toggleCarEditSellPrice = this.toggleCarEditSellPrice.bind(this);
    this.onSubmitCarEditSellPrice = this.onSubmitCarEditSellPrice.bind(this);
    this.loadCarData = this.loadCarData.bind(this);
    this.loadCarDataAsync = this.loadCarDataAsync.bind(this);
    this.loadBranchData = this.loadBranchData.bind(this);
    this.loadCarStatusData = this.loadCarStatusData.bind(this);
    this.loadCarStructureData = this.loadCarStructureData.bind(this);
    this.getDataCarSearch = this.getDataCarSearch.bind(this);
    this.getDataCarSearchWithCriteria = this.getDataCarSearchWithCriteria.bind(this);
    this.checkUserDelete = this.checkUserDelete.bind(this);
    this.loadBanks = this.loadBanks.bind(this);
    this.onLogoutAction = this.onLogoutAction.bind(this);
    this.onCarLicienseStatusIdChange = this.onCarLicienseStatusIdChange.bind(this);
    this.onCarPersonVatIdChange = this.onCarPersonVatIdChange.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.loadCarPageWithParams = this.loadCarPageWithParams.bind(this);
    // this.onCarDepositRefund = this.onCarDepositRefund.bind(this)
    // this.toggleCarDepositRefund = this.toggleCarDepositRefund.bind(this);
    this.loadCarBrandSearch = this.loadCarBrandSearch.bind(this);
    //
    this.toggleCarQuotation= this.toggleCarQuotation.bind(this);
    this.onCarQuotation = this.onCarQuotation.bind(this);
    this.onExcelCostPerCar = this.onExcelCostPerCar.bind(this);
    this.onCarHistory = this.onCarHistory.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillMount () {
    // this.mounted = true;
    // const queryString = require('query-string');
    // let query = queryString.parse(this.props.location.search);
    // console.log(query)
    // console.log(query.p)

    let user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
      user_type : user.type
    })
  }

  componentWillUnmount () {
    this.mounted = false;
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadBranchData () {
    if(this.mounted) {
      axios.get(APIURL + '/branch/')
        .then(res => {
          this.setState({ branch: res.data })
        }).catch(error => {
        this.setState({ branch: [] })
      })
    }
  }

  loadCarStatusData () {
    if(this.mounted) {
      axios.get(APIURL + '/car_status/search/0')
        .then(res => {
          this.setState({ car_status: res.data })
        }).catch(error => {
        this.setState({ car_status: [] })
      })
    }
  }

  loadCarStructureData () {
    if(this.mounted) {
      axios.get(APIURL + '/car_structure/')
        .then(res => {
          this.setState({ car_structure: res.data })
        }).catch(error => {
        this.setState({ car_structure: [] })
      })
    }
  }

  loadCarData (page) {
    let uri = ''

    if (typeof page === 'undefined') {
      page = 1
    }

    if(this.state.user_type === 'admin'){
      uri = APIURL + '/car/page/'+page;
    }else if(this.state.user_type === 'sale'){
      uri = APIURL + '/car/page/'+page;
    }else if(this.state.user_type === 'manager'){
      // uri = APIURL + '/carsale/page/'+page;
      uri = APIURL + '/car/page/'+page;
    }else if(this.state.user_type === 'technician'){
      if (port === 9041) {
        uri = APIURL + '/car/page/'+page;
      } else {
        uri = APIURL + '/cartechnician/page/'+page;
      }
    }else {
      uri = APIURL + '/car/page/'+page;
    }

    this.setState({
      isLoading: true
    },() => {

      axios.get(uri)
        .then(res => {
          if(this.mounted) {

            let result = res.data
            let cars = result.cars
            let carcount = result.carcount

            let sum_cost_all = 0
            let sum_vat_buy = 0

            let sum_withholding = 0

            if (carcount[0].sum_cost !== undefined) {
              // console.log(carcount[0])
              let sum_cost = carcount[0].sum_cost
              let sum_repair = carcount[0].sum_repair
              let sum_other = carcount[0].sum_other
              //
              let sum_cost_commissions = carcount[0].sum_cost_commissions
              let sum_car_cost_act = carcount[0].sum_car_cost_act
              let sum_car_cost_tax = carcount[0].sum_car_cost_tax

              // ' sum(car.car_cost_commissions) as sum_cost_commissions, ' +
              // ' sum(car.car_cost_act) as sum_car_cost_act, ' +
              // ' sum(car.car_cost_tax) as sum_car_cost_tax, ' +

              sum_vat_buy = carcount[0].sum_vat_buy === undefined ? 0 : carcount[0].sum_vat_buy
              sum_withholding = carcount[0].sum_withholding === undefined ? 0 : carcount[0].sum_withholding
              sum_cost_all = sum_cost + sum_repair + sum_other + sum_cost_commissions + sum_car_cost_act + sum_car_cost_tax
            }

            this.setState({
              lists: cars,
              page: page,
              carLeasingList: [],
              isLoading: false,
              count_car: carcount[0].count_car,
              sum_cost_all: sum_cost_all,
              sum_vat_buy: sum_vat_buy,
              sum_withholding: sum_withholding,
            })
          }
        }).catch(error => {
        //this.setState({lists: []})
      })

    });

  }

  loadCarDataAsync (page) {
    let uri = ''

    if (typeof page === 'undefined') page = 1

    if(this.state.user_type === 'admin'){
      uri = APIURL + '/car/page/'+page;
    }else if(this.state.user_type === 'manager'){
      uri = APIURL + '/car/page/'+page;
    }else if(this.state.user_type === 'sale'){
      // uri = APIURL + '/carsale/page/'+page;
      uri = APIURL + '/car/page/'+page;
    }else if(this.state.user_type === 'technician'){
      if (port === 9041) {
        // jingjaiauto
        uri = APIURL + '/car/page/'+page;
      } else {
        uri = APIURL + '/cartechnician/page/'+page;
      }

    }

    axios.get(uri)
      .then(res => {
        this.setState({
          lists: res.data,
          page: page,
          carLeasingList: []
        })
      }).catch(error => {
      //this.setState({lists: []})
    })
  }

  searchByCarStructure(structure_name) {
    var user_type = this.state.user_type;
    var uri_structure = APIURL + '/car/structure/'+user_type+'/'+structure_name;
    axios.get(uri_structure)
      .then(res => {
        this.setState({
          lists: res.data,
          carLeasingList: []
        })
      }).catch(error => {
      this.setState({lists: []})
    })
  }

    loadCountCarData(page) {

      // ยกเลิกไม่ใช้งาน
      return

      if(this.mounted) {
        let uri = ''
        if (this.state.user_type === 'admin') {
          uri = APIURL + '/car/getcount/count'
        } else if (this.state.user_type === 'sale') {
          uri = APIURL + '/car/getcount/count'
        } else if (this.state.user_type === 'manager') {
          // uri = APIURL + '/carsale/getcount/count';
          uri = APIURL + '/car/getcount/count'
        } else if (this.state.user_type === 'technician') {
          uri = APIURL + '/cartechnician/getcount/count';
        } else {
          uri = APIURL + '/car/getcount/count'
        }

        this.setState({
          isLoading: true
        }, () => {
          axios.get(uri)
            .then(res => {
              let sum_cost_all = 0
              let sum_vat_buy = 0
              let sum_withholding = 0
              if (res.data[0].sum_cost !== undefined) {
                let sum_cost = res.data[0].sum_cost
                let sum_repair = res.data[0].sum_repair
                let sum_other = res.data[0].sum_other

                // console.log('res.data[0].sum_vat_buy=', res.data[0].sum_vat_buy)

                sum_vat_buy = res.data[0].sum_vat_buy === undefined ? 0 : res.data[0].sum_vat_buy
                sum_withholding = res.data[0].sum_withholding === undefined ? 0 : res.data[0].sum_withholding

                sum_cost_all = sum_cost + sum_repair + sum_other
              }

              // console.log('sum_vat_buy=', sum_vat_buy)

              this.setState({
                count_car: res.data[0].count_car,
                sum_cost_all: sum_cost_all,
                sum_vat_buy: sum_vat_buy,
                sum_withholding: sum_withholding
              }, () => this.loadCarData(page));

            }).catch(error => {
              console.log(error)
              this.setState({ isLoading: false })
          })
        })
      }
    }

  componentDidMount () {
    this.mounted = true;
    // let params = this.props.params;
    // console.log('params=',params)
    this.loadGroupPermission();
    this.loadCarData(1)
    // this.loadCountCarData(1)
    this.checkUserDelete()
    this.loadBranchData()
    this.loadCarStatusData()
    this.loadCarStructureData()
    this.loadBanks()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.loadCarBrandSearch();
  }

  loadCarBrandSearch(){
    axios.get(APIURL + '/car_brand/car/search')
        .then(res => {
          // console.log(res.data)
          this.setState({car_brand: res.data})
        }).catch(error => {
      this.setState({car_brand: []})
    })
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  loadBanks () {
    axios.get(APIURL + '/bank/')
      .then(res => {
        this.setState({banks: res.data})
      }).catch(error => {
      this.setState({banks: []})
    })
  }

  checkUserDelete() {
    try {
      let user = this.state.user.user
      let uri = APIURL + '/account/' + user
      // console.log('user='+user)
      axios.get(uri)
        .then(res => {
          if (res.data.length === 0) {
            this.onLogoutAction()
          } else {
            let data = res.data[0];

            if (data.type !== this.state.user_type) {

              let user = JSON.parse(this.props.user);
              user.type = data.type
              user.fullname = data.fullname
              // {"user":"bosscar","fullname":"support","type":"manager"}

              let newUserInfo = JSON.stringify(user)
              this.props.onChangeUserType(data.type, newUserInfo);

              // window.location.reload(false);
              window.location.reload(true);

            }
          }
        }).catch(error => {
        this.onLogoutAction()
      })
    } catch (e) {
      this.onLogoutAction()
    }
  }

  onLogoutAction(){
    //alert('Logout');
    this.props.onLogout();
    this.props.history.push('/login');
  }

  onCarStatusEdit (car) {
    this.setState({car_edit_status: car},()=>{
      this.toggleCarStatusEdit()
    })
  }

  /*onCarDepositRefund (car) {
    this.setState({car_deposit_refund: car},()=>{
     this.toggleCarDepositRefund()
    })
  }*/

  toggleCarStatusEdit(){
    this.setState({toggle_car_status_edit: !this.state.toggle_car_status_edit})
  }

  onCarStatusChange(car_id,car_status_id){
    axios.put(APIURL + '/car/car_status/'+car_id,{
      car_id: car_id,
      car_status_id: car_status_id,
    })
    .then(res => {
      this.loadCarData()
      this.toggleCarStatusEdit()
    }).catch(error => {

    })
  }

  toggleCarCalculate() {
    this.setState({
      toggle_car_calculate: !this.state.toggle_car_calculate
    });
  }

  toggleCarDelete() {
    this.setState({
      toggle_car_status_delete: !this.state.toggle_car_status_delete
    });
  }

  toggleCarLeasing() {
    this.setState({
      toggle_car_status_leasing: !this.state.toggle_car_status_leasing
    });
  }

  toggleCarQuotation() {
    this.setState({
      toggle_car_quotation: !this.state.toggle_car_quotation
    });
  }

  /*toggleCarDepositRefund(){
    this.setState({
      toggle_car_deposit_refund: !this.state.toggle_car_deposit_refund
    });
  }*/

  toggleCarEditSellPrice()  {
    this.setState({
      toggle_car_edit_sell_price: !this.state.toggle_car_edit_sell_price
    });
  }

  toggleCarShare() {
    this.setState({
      toggle_car_share: !this.state.toggle_car_share
    },()=>{
      if(this.state.toggle_car_share===false){
        // this.loadCarData(1)
        this.loadCountCarData(1)
      }
    });

  }

  onCarCalculate(car){
    this.setState({car_calculate: car},()=>{
      this.toggleCarCalculate()
    })
  }

  onCarQuotation(car){
    this.setState({car_quotation: car},()=>{
      this.toggleCarQuotation()
    })
  }

  onExcelCostPerCar(car) {

    // call api for gen ต้นทุนรายคัน
    let reqData = {
      car_id: car.car_id,
      customer_id: '',
      car_sell_id: ''
    }

    this.setState({
      isLoading: true
    })

    let uri = APIURL + '/car_cost_excel'
    axios.post(uri, reqData)
      .then(async res => {
        console.log(res.data)

        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const carLicenseShort = res.data.carLicenseShort
        const fileName = 'ต้นทุนรายคัน_'+carLicenseShort+'_'+currentDatetime+'.xlsx'

        this.setState({
          isLoading: false
        })

        await save(excelBuffer, fileName)


      }).catch(error => {
       console.log('error:', error)
    })

  }

  onCarHistory(car) {
    console.log('--onCarHistory')
  }

  onCarShare(car){
    this.setState({car_share: car},()=>{
      this.toggleCarShare()
    })
  }

  onCarDelete(car) {
    this.setState({car_delete: car},()=>{
      this.toggleCarDelete()
    })
  }

  onCarLeasing(car) {
    this.setState({car_leasing: car},()=>{
      this.toggleCarLeasing()
    })
  }

  onCarEditSellPrice(car) {
    this.setState({car_edit_sell_price: car},()=>{
      this.toggleCarEditSellPrice()
    })
  }

  onCarShareChange(car_id){
      //alert("onCarShareChange car.car_id="+car_id)
  }

    searchCar () {
        var search_text = this.refs.search_car_name.value
        //alert(search_text);
        if (search_text !== ""){
          this.setState ({is_search: true})
            this.getDataCarSearch(search_text)

        }else {
          this.setState ({is_search: false},()=>{
            this.loadCarData(1)
          })
        }

    }

    getDataCarSearchWithCriteria () {
      let search_text = this.refs.search_car_name.value
      let branch_id = this.state.branch_id
      let car_status_id = this.state.car_status_id
      let car_structure_id = this.state.car_structure_id
      let order_by = this.state.order_by
      let car_brand_id = this.state.car_brand_id

      let data = {
        search_text,
        branch_id,
        car_status_id,
        car_structure_id,
        order_by,
        car_brand_id
      }

      let uri = APIURL + '/car/criteria';

      this.setState({isLoading: true}, () => {
        axios.post(uri, data)
          .then(res => {
            this.setState({lists: res.data, isLoading: false, is_search: true})
          }).catch(error => {
          this.setState({lists: [], isLoading: false})
        })
      })
    }

    getDataCarSearch (search_text) {
      let uri = '';
      if(this.state.user_type === 'admin'){
        uri = APIURL + '/car/search/' + search_text;
      }else if(this.state.user_type === 'sale'){
        // uri = APIURL + '/carsale/search/'+search_text;
        uri = APIURL + '/car/search/' + search_text;
      }else if(this.state.user_type === 'technician'){
        if (port === 9041) {
          // jingjaiauto
          uri = APIURL + '/car/search/' + search_text;
        } else {
          uri = APIURL + '/cartechnician/search/'+search_text;
        }
      } else {
        uri = APIURL + '/car/search/' + search_text;
      }

        axios.get(uri)
            .then(res => {
                this.setState({lists: res.data})
            }).catch(error => {
            this.setState({lists: []})
        })
    }

  onRefreshCar(){
    this.refs.search_car_name.value = ''
    this.setState ({
      is_search: false,
      branch_id: '',
      car_status_id: '',
      car_structure_id: '',
      car_brand_id: '',
      order_by: '',
      isLoading: true
    },()=>{
      this.loadCarData(1)
    })
  }

  carDelete(car_id) {

    let page = this.state.page
    axios.delete(APIURL + '/car/' + car_id).then(res => {
      if (res.status === 200) {
        this.toggleCarDelete()
        this.loadCarData(page)
        //this.loadCountCarData(1)
      }
    })

    /*if(this.state.user_type === 'admin' || (port === '9021' &&  this.state.user.type === 'manager')){
      // let car_id = car.car_id
      let page = this.state.page
      axios.delete(APIURL + '/car/' + car_id).then(res => {
        if (res.status === 200) {
          this.toggleCarDelete()
          this.loadCarData(page)
          //this.loadCountCarData(1)
        }
      })
    } else {
      AlertError('เฉพาะสิทธิ์ผู้ดูแลระบบเท่านั้น');
    }*/

  }

  onSubmitCarLeasing(e, leasingData) {
    e.preventDefault();

    var action = e.target.action.value;

     var data = {
       'leasingData': leasingData,
       'user' : this.state.user.user
     }

    if(action==='add') {
      axios.post(APIURL + '/car_leasing/new/' + this.state.car_leasing.car_id, data)
        .then((res) => {
          //if (res.status === 200) {
            this.toggleCarLeasing()
            this.loadCarLeasingList(this.state.car_leasing.car_id)
          //}
        })
        .catch((err) => {
          console.log(err);
        });
    }else if(action==='edit') {
     // edit
      axios.put(APIURL + '/car_leasing/edit/' + this.state.car_leasing.car_id, data)
        .then((res) => {
          if (res.status === 200) {
            this.toggleCarLeasing()
            this.loadCarLeasingList(this.state.car_leasing.car_id)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }


  async onSubmitCarEditSellPrice(e, SellPrice) {
    e.preventDefault();
    // let res = await axios.put(APIURL + '/car/car_sell_price/'+SellPrice.car_id, SellPrice)
    let res = await axios.put(APIURL + '/car/car_price/vatbuy/'+SellPrice.car_id, SellPrice)

    if (res.status === 200) {
      // await this.loadCarDataAsync(this.state.page)
      await this.loadCarData(this.state.page)
      this.toggleCarEditSellPrice()
    }
  }


  loadCarLeasingList(car_id) {
    var uri =  APIURL + '/car_leasing/'+car_id;
    axios.get(uri)
      .then(res => {
        this.setState({carLeasingList: res.data})
      }).catch(error => {
      this.setState({carLeasingList: []})
    })
  }


  onCarExplainFormEdit(e, car_id) {
    e.preventDefault();
    let explain = e.target.value;

    let car_list = this.state.lists.map(car => car.car_id===car_id? {...car, car_explain: explain , car_edit: true  } : car);

    this.setState({
      lists: car_list
    });
  }

  onCarLicienseStatusIdChange(e, car_id) {
    e.preventDefault();
    let liciense_status_id = Number(e.target.value);
    // console.log('liciense_status_id=', liciense_status_id)
    let car_list = this.state.lists.map(car => car.car_id === car_id ? {...car, liciense_status_id: liciense_status_id } : car);
    this.setState({
      lists: car_list
    }, () => {
      // update to api
      let uri =  APIURL + '/car/liciense_status/'+car_id;
      let data = {liciense_status_id: liciense_status_id};
      axios.put(uri,data).then(res => {
        // console.log(res.data)
      }).catch(error => {
        console.log('error: ', error)
      })
    });
  }

  onCarPersonVatIdChange(e, car_id) {
    e.preventDefault();
    let person_vat_id = Number(e.target.value);
    // console.log('person_vat_id=', person_vat_id)
    let car_list = this.state.lists.map(car => car.car_id === car_id ? {...car, person_vat_id: person_vat_id } : car);
    this.setState({
      lists: car_list
    }, () => {
      // update to api
      let uri =  APIURL + '/car/person_vat/'+car_id;
      let data = {person_vat_id: person_vat_id};
      axios.put(uri,data).then(res => {
        // console.log(res.data)
      }).catch(error => {
        console.log('error: ', error)
      })
    });
  }

  onCarExplainUpdate(car_id, car_explain) {
    // update car_explain
    var uri =  APIURL + '/car/car_explain/'+car_id;
    var data = {car_explain: car_explain};
    axios.put(uri,data)
      .then(res => {

         if(res.status===200){
           let car_list = this.state.lists.map(car => car.car_id===car_id? {...car, car_edit: false, car_save: true  } : car);
           this.setState({
             lists: car_list
           });

           setTimeout(() => {
             let car_list = this.state.lists.map(car => car.car_id===car_id? {...car, car_edit: false, car_save: false  } : car);
             this.setState({
               lists: car_list
             });
           }, 2000);
         }
      })
      .catch(error => {

      })
  }

  getRenderTextCarSearch() {
    const {
      branch_id,
      car_status_id,
      car_structure_id,
      order_by,
      car_brand_id,
      // xx
      branch,
      car_status,
      car_structure,
      orders,
      car_brand,
      lists
    } = this.state

    let searchText = 'ค้นหาตาม '

    if (branch_id !== '') {
     let branch_name = branch.filter(b => b.branch_id === parseInt(branch_id, 10))[0].branch_name
      searchText += ' สาขา '+ branch_name
    }

    if (car_status_id !== '') {
      let car_status_name = car_status.filter(b => b.car_status_id === parseInt(car_status_id, 10))[0].car_status_name
      searchText += ' สถานะ '+ car_status_name
    }

    if (car_structure_id !== '') {
      let car_structure_name = car_structure.filter(b => b.car_structure_id === parseInt(car_structure_id, 10))[0].car_structure_name
      searchText += ' สถานะ '+ car_structure_name
    }

    if (order_by !== '') {
      let order_name = orders.filter(b => b.order_id === order_by)[0].order_name
      searchText += ' เรียงตาม '+ order_name
    }

    if (lists.length > 0) {
      searchText += ' พบ '+lists.length+' คัน'
    }

    if (car_brand_id !== '') {
      // console.log('car_brand_id = ',typeof car_brand_id)
      let car_brand_name = car_brand.filter(b => b.car_brand_id === Number(car_brand_id))[0].car_brand_name
      searchText += ' ยี่ห้อ '+ car_brand_name
    }

    return searchText

  }

  loadCarPageWithParams(pg) {
    let page = Number(this.state.page)
    // console.log('page=', page, typeof page, '---', 'fn pg=', pg, typeof pg)
    // console.log('pg=', pg)

    if (Number(page) === Number(pg)) {
      // no action
      return
    } else {
      this.setState({
        page: pg
      }, () => this.loadCarData(pg))
    }
  }

  render () {


    const { page } = this.state;
    const { pg } = this.props.match.params;



    let all_page = Math.ceil(this.state.count_car/PAGE10);
    let page_array = Array.from(Array(all_page).keys());

    let width = this.state.width

    let tab_pages = page_array.map((page,index)=>{
          let page_tmp = page+1
          /*if (page_tmp % 29 == 0) {
            return (
              <>
                <li className="page-item" key={index}>
                  <button className="page-link"  onClick={()=>{   this.loadCarData(page_tmp) }}>
                    &nbsp;{page_tmp}&nbsp;
                  </button>
                </li>
                <br/>
              </>
            )
          } else {
            return (
              <li className="page-item" key={index}>
                <button className="page-link"  onClick={()=>{   this.loadCarData(page_tmp) }}>
                  &nbsp;{page_tmp}&nbsp;
                </button>
              </li>
            )
          }*/
      return (
        <li className="page-item" style={{borderColor: 'rgba(111,111,111,0)'}} key={index}>
          <a className="page-link"
                  style={Number(pg) === (index+1) ? {backgroundColor: '#20a8d8', cursor: 'pointer'} : {cursor: 'pointer'}}
                  onClick={()=>{
            // this.loadCarData(page_tmp)
            // let queryPage = 'p=' + page_tmp
            let target = '/cars/list/' + page_tmp
            // console.log(target)
            // return <Redirect to={{ pathname: '/cars/list', search: `?${queryPage}` }} />
            // return <Redirect push to={target} />
            this.props.history.push(target)
            //return <Redirect push to={target} replace={true} />
          }}>
            &nbsp;{page_tmp}&nbsp;
          </a>
        </li>
      )

      })

    let branch_list = this.state.branch.map((branch,index)=>{
      return(
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    let car_status_list = this.state.car_status.map((car_status, index) => {
      return (
        <option key={index} value={car_status.car_status_id}>{car_status.car_status_name}</option>
      )
    })

    let car_structure_list = this.state.car_structure.map((car_structure, index) => {
      return (
        <option key={index} value={car_structure.car_structure_id}>{car_structure.car_structure_name}</option>
      )
    })

    let order_list = this.state.orders.map((order, index) => {
      return (
        <option key={index} value={order.order_id}>{order.order_name}</option>
      )
    })

    let brand_list = this.state.car_brand.map((car_brand, index) => {
      return (
          <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
      )
    })

    let width_box = 128

    // console.log('width=', width)
    if (width <= 980){
      width_box = 112
    }

    if (
      page_array.length > 1
      && this.state.branch.length > 0
      && this.state.car_status.length > 0
      && this.state.car_structure.length > 0
      && this.state.orders.length > 0
      && this.state.car_brand.length > 0
      && !isNaN(pg)
    ) {
      this.loadCarPageWithParams(Number(pg))
    }

    return (
      <div className="card">

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block" style={styles.overflowY}>
          <div className="row">

              <div className="card card-accent-info">
                <div className="card-header">
                  <div className="row">
                    <div className="form-group form-inline col-12">
                      <div className="row">
                        <select className="form-control ml-2 mr-1"
                                name="branch_id"
                                style={{...styles.selectFilter, width: width_box}}
                                value={this.state.branch_id}
                                onChange={(e) => {
                                  this.setState({branch_id: e.target.value}, () => this.getDataCarSearchWithCriteria())
                                }}
                        >
                          <option value="">สาขาทั้งหมด</option>
                          {branch_list}
                        </select>

                        <select className="form-control mr-1"
                                name="car_status_id"
                                style={{...styles.selectFilter, width: width_box}}
                                value={this.state.car_status_id}
                                onChange={(e) => {
                                  this.setState({car_status_id: e.target.value}, () => this.getDataCarSearchWithCriteria())
                                }}
                        >
                          <option value="">สถานะรถ</option>
                          {car_status_list}
                        </select>

                        <select className="form-control mr-1"
                                style={{...styles.selectFilter, width: width_box}}
                                name="car_structure_id"
                                value={this.state.car_structure_id}
                                onChange={(e) => {
                                  this.setState({car_structure_id: e.target.value}, () => this.getDataCarSearchWithCriteria())
                                }}
                        >
                          <option value="">ประเภทรถ</option>
                          {car_structure_list}
                        </select>

                        {port === 9040 ?
                            <select className="form-control mr-1"
                                    name="car_brand_id"
                                    style={{...styles.selectFilter, width: width_box}}
                                    value={this.state.car_brand_id}
                                    onChange={(e) => {
                                      this.setState({car_brand_id: e.target.value}, () => this.getDataCarSearchWithCriteria())
                                    }}
                            >
                              <option value="">ยี่ห้อรถ</option>
                              {brand_list}
                            </select>
                            :
                          <select className="form-control mr-1"
                                  name="order_by"
                                  style={{...styles.selectFilter, width: width_box}}
                                  value={this.state.order_by}
                                  onChange={(e) => {
                                    this.setState({order_by: e.target.value}, () => this.getDataCarSearchWithCriteria())
                                  }}
                          >
                            <option value="">เรียงตาม</option>
                            {order_list}
                          </select>
                        }

                          <input
                            ref="search_car_name"
                            style={styles.searchCar}
                            /*className={width<=980 ? 'hidden' : 'form-control'}*/
                            className="form-control mr-1"
                            placeholder="ค้นหาชื่อรถ ทะเบียนรก"
                            type="text"
                          />
                          {/*&nbsp;*/}
                          <button
                            className="btn btn-sm btn-head-bar mr-1"
                            onClick={() => this.getDataCarSearchWithCriteria()}
                            style={styles.buttonHeight}
                          >
                            <i className="icon-search"/>ค้นหา
                          </button>
                          {/*&nbsp;*/}
                          <button
                            className="btn btn-sm btn-head-bar"
                            onClick={this.onRefreshCar.bind(this)}
                            style={styles.buttonHeight}
                          >
                            <i className="icon-refresh"/>
                          </button>
                          &nbsp;&nbsp;&nbsp;&nbsp;

                         {
                            checkGroupPermission(1, this.state.grouppermissions).created ? (
                              <Link to="/cars/new">
                                <button
                                  className="btn btn-head-bar float-right"
                                  style={styles.buttonHeight}
                                >
                                  <i className="icon-plus"/>&nbsp; เพิ่มรถใหม่
                                </button>
                              </Link>
                            ) : null
                          }

                      </div>
                    </div>

                    {/*
                    <div className="form-group form-inline col-5">
                    </div>
                    */}

                  </div>

                      {/*<strong> ข้อมูลรถ </strong>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <div className="btn-group">
                        <button type="button"
                                className="btn btn-outline-primary  btn-md-width"
                                onClick={()=> this.searchByCarStructure('เก๋ง')}
                        >เก๋ง</button>
                        <button type="button"
                                className="btn btn-outline-danger   btn-md-width"
                                onClick={()=>this.searchByCarStructure('กระบะ')}
                        >กระบะ</button>
                        <button type="button"
                                className="btn btn-outline-success  btn-md-width"
                                onClick={()=>this.searchByCarStructure('แวน')}
                        >แวน</button>
                      </div>*/}

                </div>

                <div className="card-block">

                  <div className={"row pl-3 pb-2"}>

                    <div className="form-group form-inline col-8">
                      <table width='100%'>
                        <tbody>
                          <tr>
                            <td style={{width: 250}}>
                              {
                                this.state.is_search === true ? <h5 className='p-0'>{this.getRenderTextCarSearch()}</h5> : <h5 className='p-0' >จำนวนรถ {this.state.count_car} คัน</h5>
                              }
                            </td>
                            <td >
                              {
                                this.state.sum_cost_all > 0 && this.state.sum_vat_buy > 0 && this.state.user_type === 'admin' ?
                                  <h5 className='p-0' >รวมทุน (ไม่รวม Vat) : {this.state.sum_cost_all.toLocaleString()} บาท</h5>
                                  : ''
                              }

                              {
                                this.state.sum_cost_all > 0 && this.state.sum_vat_buy === 0 && this.state.user_type === 'admin' ?
                                  <h5 className='p-0' >รวมทุน : {this.state.sum_cost_all.toLocaleString()} บาท</h5>
                                  : ''
                              }

                            </td>
                          </tr>
                          <tr className={this.state.sum_vat_buy > 0 && this.state.user_type === 'admin' ? '': 'hidden'} >
                            <td className='form-inline'>
                              &nbsp;
                            </td>
                            <td>
                              {
                                this.state.sum_vat_buy > 0 && this.state.user_type === 'admin' ?
                                  (
                                    <h5 className='p-0'>รวม Vat ซื้อ : {this.state.sum_vat_buy.toLocaleString()} บาท</h5>
                                  )
                                  : ''
                              }
                            </td>
                          </tr>
                          <tr className={this.state.sum_withholding > 0 && this.state.user_type === 'admin' ? '': 'hidden'} >
                            <td className='form-inline'>
                              &nbsp;
                            </td>
                            <td>
                              {
                                this.state.sum_withholding > 0 && this.state.user_type === 'admin' ?
                                    (
                                        <h5 className='p-0'>รวมภาษีหัก ณ ที่จ่าย (ซื้อเข้า) : {this.state.sum_withholding.toLocaleString()} บาท</h5>
                                    )
                                    : ''
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="form-group col-4 text-right">

                      {/*{
                        this.state.user.type === 'admin' || this.state.user.type === 'manager'  ? (
                          <Link to="/report/carin" target='_blank'>
                            <button className="btn btn-success float-right ml-1">
                              รายงานรถเข้า
                            </button>
                          </Link>
                        ) : null
                      }*/}

                      {
                        port === '9021' ? (
                          this.state.user.type === 'admin' || this.state.user.type === 'manager' || this.state.user.type === 'account' ? (
                            <Link to="/report/carintabs" target='_blank'>
                              <button className="btn btn-success float-right ml-1">
                                รายงานรถเข้า
                              </button>
                            </Link>
                          ) : null
                        ) : (
                          this.state.user.type === 'admin' || this.state.user.type === 'manager' ? (
                            <Link to="/report/carintabs" target='_blank'>
                              <button className="btn btn-success float-right ml-1">
                                รายงานรถเข้า
                              </button>
                            </Link>
                          ) : null
                        )
                      }

                      <Link to="/carin/all" >
                        <button className="btn btn-info float-right ">
                          รายละเอียด
                        </button>
                      </Link>

                    </div>

                  </div>

                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">รูปรถ</th>
                      <th className="text-center">ชื่อรถ</th>
                      {/*<th className={this.state.user_type ==='admin' ? "text-center" :  "hidden"}>ราคาทุน</th>*/}
                      <th
                          className={this.state.user_type === 'technician' ? 'hidden' : 'text-center'}
                      >
                        ราคา
                      </th>
                      {/*<th className={this.state.user_type ==='admin' ? "text-center" :  "hidden"} >กำไร</th>
                      <th className="text-center">สถานะ</th>*/}
                      <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>

                    <CarRow
                      cars={this.state.lists}
                      onCarStatusEdit={this.onCarStatusEdit}
                      onCarCalculate={this.onCarCalculate}
                      onCarShare = {this.onCarShare}
                      onCarDelete = {this.onCarDelete}
                      onCarLeasing={this.onCarLeasing}
                      onCarEditSellPrice={this.onCarEditSellPrice}
                      user_type={this.state.user_type}
                      carLeasingList = {this.state.carLeasingList}
                      onCarExplainFormEdit = {this.onCarExplainFormEdit}
                      onCarExplainUpdate = {this.onCarExplainUpdate}
                      onCarLicienseStatusIdChange = {this.onCarLicienseStatusIdChange}
                      onCarPersonVatIdChange = {this.onCarPersonVatIdChange}
                      page = {this.state.page}
                      count = {this.state.is_search === true ? this.state.lists.length : this.state.count_car}
                      branch={this.state.branch}
                      banks={this.state.banks}
                      sum_vat_buy={this.state.sum_vat_buy}
                      sum_withholding={this.state.sum_withholding}
                      grouppermissions={this.state.grouppermissions}
                      onCarQuotation={this.onCarQuotation}
                      onExcelCostPerCar={this.onExcelCostPerCar}
                      onCarHistory={this.onCarHistory}
                    />

                  </table>

                  {
                   Number(page) !== Number(pg) ? (
                       <ul className= { this.state.is_search ? "hidden" : "pagination d-flex flex-wrap pagination-sm" }>
                         {tab_pages}
                       </ul>
                   ) : (
                       <ul className= { this.state.is_search ? "hidden" : "pagination d-flex flex-wrap pagination-sm" }>
                         {tab_pages}
                       </ul>
                   )
                  }



                </div>
              </div>

              <Modal isOpen={this.state.toggle_car_status_edit}
                     toggle={this.toggleCarStatusEdit}
                     className={'modal-lg '}
                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
              >
                <ModalBody>
                     <CarEditStatus
                        car = {this.state.car_edit_status}
                        onHandle = {
                          {
                            cancel: this.toggleCarStatusEdit,
                            change: this.onCarStatusChange
                          }
                        }
                     />
                </ModalBody>
              </Modal>

              <Modal isOpen={this.state.toggle_car_calculate}
                     toggle={this.toggleCarCalculate}
                     className={'modal-lg ' + this.props.className}
                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
                <ModalBody>
                  <CarCalculate
                    car={this.state.car_calculate}
                    onToggle={this.toggleCarCalculate}
                  />
                </ModalBody>
              </Modal>

            <Modal isOpen={this.state.toggle_car_quotation}
                   toggle={this.toggleCarQuotation}
                   className={'modal-md ' + this.props.className}
                   style={width<=980 ?{maxWidth:600}:{maxWidth:600}}>
              <ModalBody>
                <CarQuotation
                    car={this.state.car_quotation}
                    onToggle={this.toggleCarQuotation}
                />
              </ModalBody>
            </Modal>

              <Modal isOpen={this.state.toggle_car_share}
                     toggle={this.toggleCarShare}
                     className={'modal-lg ' + this.props.className}
                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
              >
                <ModalBody>
                  <CarShare
                    car={this.state.car_share}
                    onHandle = {
                      {
                        cancel: this.toggleCarShare,
                        change: this.onCarShareChange
                      }
                    }
                  />
                </ModalBody>
              </Modal>


              <Modal isOpen={this.state.toggle_car_status_delete} toggle={this.toggleCarDelete}
                     className={'modal-danger'}>
                <ModalHeader toggle={this.toggleCarDelete}>ลบข้อมูล</ModalHeader>
                <ModalBody>
                  <strong> คุณต้องการลบข้อมูล {this.state.car_delete.car_name} [{this.state.car_delete.car_id}] ใช่หรือไม่ ?? </strong>
                  <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                </ModalBody>
                <ModalFooter>
                  <Button color="danger"
                          onClick={this.carDelete.bind(this, this.state.car_delete.car_id)}>ตกลง</Button>{' '}
                  <Button color="secondary" onClick={this.toggleCarDelete}>ยกเลิก</Button>

                </ModalFooter>
              </Modal>


              <Modal isOpen={this.state.toggle_car_status_leasing}
                     toggle={this.toggleCarLeasing}
                     className={'modal-md ' + this.props.className}
                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
              >
                <ModalBody>
                  <CarLeasingFormNew
                    car={this.state.car_leasing}
                    onHandle = {
                      {
                        cancel: this.toggleCarLeasing
                      }
                    }
                    onSubmit = {this.onSubmitCarLeasing}
                    user_type={this.state.user_type}
                  />
                </ModalBody>
              </Modal>

              {/*<Modal isOpen={this.state.toggle_car_deposit_refund}
                     toggle={this.toggleCarDepositRefund}
                     className={'modal-md ' + this.props.className}
                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
              >
                <ModalBody>
                  <CarDepositRefundFormNew
                      car={this.state.car_deposit_refund}
                      onHandle = {
                        {
                          cancel: this.toggleCarDepositRefund
                        }
                      }
                      // onSubmit = {this.onSubmitCarLeasing}
                      user_type={this.state.user_type}
                  />
                </ModalBody>
              </Modal>*/}

              <Modal isOpen={this.state.toggle_car_edit_sell_price}
                     toggle={this.toggleCarEditSellPrice}
                     className={'modal-md ' + this.props.className}
                     style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
              >
                <ModalBody>
                  <CarEditSellPrice
                    car={this.state.car_edit_sell_price}
                    onHandle = {
                      {
                        cancel: this.toggleCarEditSellPrice
                      }
                    }
                    onSubmit = {this.onSubmitCarEditSellPrice}
                  />
                </ModalBody>
              </Modal>

          </div>
        </div>
      </div>
    )
  }

}

//  style={styles.buttonHeight}
const styles = {
    searchCar: {
        marginLeft: 0,
        marginTop: 5,
        marginBottom: -8,
        width: 180,
        borderRadius:5,
        display: 'inline'
    },

    buttonHeight: {
      height: 36,
      paddingTop: 0,
      paddingBottom:0,
      borderRadius:5,
      display: 'inline',
      marginTop: 5,
      marginBottom: -8,
    },

    selectFilter: {
      marginTop: 5,
      marginBottom: -8,
      borderRadius:5,
      display: 'inline'
  },

  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  type: state.auth.type,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = (dispatch) => ({
  onLoginSuccess(data) {
    dispatch(loginUserSuccess(data.token,JSON.stringify(data.user)))
  },
  onChangeUserType(type, user) {
    dispatch(changeUserType(type, user))
  },
  onLogout() {
    dispatch(logoutAndRedirect())
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(CarList)

// CarRepairReport
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { APIURL } from '../../config/config';
import axios from 'axios';
import Loading from '../Loading';
import { Modal, ModalBody } from 'reactstrap';
import PreviewImage from '../../views/PreviewImage';
import {save} from "save-file";
// import DatePicker from 'react-datepicker/es';
// import DatePicker from 'react-datepicker'
// import moment from 'moment';
// import { Link } from 'react-router-dom';

class CarRepairReport extends Component{
  constructor (props) {
    super(props)
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      expenseCarRepairList: [],
      sumCarRepair: 0,
      isLoading: false,
      preview_image_url: '',
      preview_image_select: false,
      branches: [],
      branch_id: '',
      type_of_sells: [
           {type: 'all', text: 'ทั้งหมด'},
           {type: 'sell', text: 'เฉพาะขายแล้ว'},
           {type: 'nosell', text: 'เฉพาะยังไม่ขาย'},
        ],
      type_of_sell: 'nosell'
    }

    // all , sell, nosell
    // this.handleDateStart = this.handleDateStart.bind(this)
    // this.handleDateEnd   = this.handleDateEnd.bind(this)
    this.loadExpenseCarRepairByCar   = this.loadExpenseCarRepairByCar.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    //
    this.loadBranchData = this.loadBranchData.bind(this)
    this.exportCarRepair = this.exportCarRepair.bind(this)

  }

  componentDidMount() {
    this.loadExpenseCarRepairByCar()
    this.loadBranchData()
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branches: res.data})
      }).catch(error => {
      this.setState({branches: []})
    })
  }


  loadExpenseCarRepairByCar() {
    const {
      branch_id,
      type_of_sell
    } = this.state
    // if(this.state.date_start > this.state.date_end){
    //   alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
    //   return
    // }
    //
    // let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
    // let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
    // let url = APIURL+'/expense/car_repair/'+date_start+'/'+date_end
    let url = APIURL+'/expense/car_repair/bycar'
    // branch_id , type_of_sell
    let data = {
      branch_id: branch_id,
      type_of_sell: type_of_sell
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(url, data).then(res => {
        if(res.status === 200){
          // console.log(res.data)
          // car_repair_cost | car_cost_repair
          let sumCarRepairAll = 0
          if(res.data.length > 0) {
            sumCarRepairAll = res.data.reduce((acc, next) => acc + next.sum_car_repair, 0)
          }
          // console.log('sumCarRepair', sumCarRepair)
          this.setState({
            expenseCarRepairList: res.data,
            sumCarRepair: sumCarRepairAll,
            isLoading: false
          })
        }
      })
    })
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  async exportCarRepair(){
    const branch_id = this.state.branch_id
    const type_of_sell = this.state.type_of_sell

    const data = {
      branch_id: branch_id,
      type_of_sell: type_of_sell
    }

    this.setState({
      isLoading: true
    }, () => {

      axios.post(APIURL + '/report/carrepairexcel', data)
          .then(async res => {

            const excelBuffer = res.data.excelBuffer
            const currentDatetime = res.data.currentDatetime
            const fileName = 'ข้อมูลสรุปค่าใช้จ่ายซ่อมรถรายคัน '+currentDatetime+'.xlsx'

            this.setState({
              isLoading: false
            })

            await save(excelBuffer, fileName)

          }).catch(error => {
        console.log('error carinexport:', error)
      })
    })
  }

  render () {
    const { expenseCarRepairList } = this.state

    const emptyList = <tr><td colSpan={8} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>

    const expenseCarRepairTR = expenseCarRepairList.map((expense, index) => (
      <tr key={index}>
        <th className="text-center">{index + 1}</th>

        <th className="text-left" width={300} >
          {expense.car_name}
        </th>
        <th className="text-center" width={100} >
          {expense.car_license_plate_new}
        </th>
        <th className="text-center" width={100} >
          {expense.car_status_name}
        </th>
        <th className="text-center" width={150}>
          {expense.branch_name}
        </th>
        <th className="text-center">
          <NumberFormat value={expense.sum_car_repair}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'} />
        </th>
        {
          this.state.type_of_sell === 'nosell' ? null :
            <th className="text-center">
              {expense.car_sell_date}
            </th>
        }
      </tr>
    ))

    const expenseBody = expenseCarRepairList.length === 0 ? emptyList : expenseCarRepairTR

    const car_branch_list = this.state.branches.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    const type_of_sell_list = this.state.type_of_sells.map((type_of_sell, index) => {
      return (
        <option key={index} value={type_of_sell.type}>{type_of_sell.text}</option>
      )
    })

    return (
      <div className="card">

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-info">
                <div className="card-header">
                  <strong className="text-title"> ข้อมูลสรุปค่าใช้จ่ายซ่อมรถรายคัน </strong>
                  {/*<button type="button"
                          onClick={this.toggleExpense}
                          className="btn btn-primary float-right">
                    <i className="icon-plus"></i>&nbsp; เพิ่มข้อมูลค่าใช้จ่าย
                  </button>
                  <Modal isOpen={this.state.toggle_expense} toggle={this.toggleExpense}
                         className={'modal-lg'}>
                    <ModalBody>
                      <ExpenseForm onToggle={this.toggleExpense}/>
                    </ModalBody>
                  </Modal>*/}
                </div>

                <div className="row col-12 mt-4">

                  <div className="form-group col-2">
                    <select className="form-control" id="branch_id" name="branch_id"
                            onChange={(e) => {
                              this.setState({
                                branch_id: e.target.value
                              }, () => this.loadExpenseCarRepairByCar())
                            }}
                    >
                      <option value=''> สาขาทั้งหมด </option>
                      {car_branch_list}
                    </select>
                  </div>

                  <div className="form-group col-3">
                    <select className="form-control" id="type_of_sell" name="type_of_sell"
                            onChange={(e) => {
                              this.setState({
                                type_of_sell: e.target.value
                              }, () => this.loadExpenseCarRepairByCar())
                            }}
                            value={this.state.type_of_sell}
                    >
                      {type_of_sell_list}
                    </select>
                  </div>

                  <div className="col-1">
                    <button type="button" style={{width: 80}} className="btn btn-sm btn-success" onClick={() => this.exportCarRepair()} > Excel </button>
                  </div>

                  <div className="form-group col-6 text-right pt-2 pb-1  ">
                    <h6>
                      รวมค่าซ่อมรถ&nbsp;
                      <NumberFormat
                        displayType={'text'}
                        thousandSeparator={true}
                        value={this.state.sumCarRepair.toFixed(0)}/>
                      &nbsp;บาท
                    </h6>
                  </div>
                </div>

                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">ข้อมูลรถ</th>
                    <th className="text-center">ทะเบียนรถ</th>
                    <th className="text-center">สถานะ</th>
                    <th className="text-center">สาขา</th>
                    <th className="text-center">รวมค่าซ่อม</th>
                    {
                      this.state.type_of_sell === 'nosell' ? null : <th className="text-center">วันที่ขาย</th>
                    }
                  </tr>
                  </thead>
                  <tbody>
                  {expenseBody}
                  </tbody>
                </table>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairReport)

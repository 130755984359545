/**
 * พิมพ์สัญญาขายรถ เงินสด
 * **/

import React, { Component } from 'react'
import axios from 'axios'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Loading from '../Loading'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { DateUtil } from '../../utils/dateUtil'
import { APIURL } from '../../config/config'
import ThaiBaht from 'thai-baht-text';
import NumberFormat from 'react-number-format'
import { AlertSuccess, AlertWarning } from '../Alert/Alert'
import { NumberUtil } from '../../utils/number-util'
import DatePickerIcon from "../DatePickerIcon";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class CarSalesContractAt extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contract_id: null,
      contract_no: '',
      write_at: '',
      // date
      startDateContract: new Date(),
      isOpenDateContract: false,
      contract_date_thai: null,
      contract_date: null,
      // end date
      seller_name: '', // ผู้ขาย
      car_name: '',
      car_license_plate: '',
      car_num_engines: '',
      car_number: '', // เลขตัวรถ
      car_year_name: '', // ปี
      car_color_name: '', // สี
      // ขายให้แก่
      buyer_name: '', // สี
      buyer_age: '',
      buyer_address: '',
      buyer_mobile: '',
      card_type: 'บัตรประชาชน',
      card_code: '',
      card_out_at: '',
      real_sell_price: 0,
      cash_pledge: 0,
      // date
      startPaidDueDate: new Date(),
      isOpenPaidDueDate: false,
      paid_due_date_thai: null,
      paid_due_date: null,
      // end date
      isExist: false,
      car_id: '',
      customer_id: '',
      car_sell_id: '',
      car_book_id: '',
      province_name : '',
      note : '',
      is_vat: 0,
      vat: 0,
      vat_amount: 0,
      total_amount: 0,
      company_setting: null,
      isExistContract: false,
      logo_image: '',
      company_address: '',
      car_miles: 0,
      // at car sale เพิ่ม
      buyer_mobile2: '',
      buyer_idline: '',
      insurance_mobile: '',
      insurance_received_number: '',
      document_contact_mobile: '',
      check_fee: 0,
      logoBase64Img: '',
    }

    this.handleDateContract = this.handleDateContract.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarSellById = this.loadCarSellById.bind(this);
    this.loadCarContract = this.loadCarContract.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this);
    this.calOutstanding = this.calOutstanding.bind(this);
    this.handlePaidDueDate = this.handlePaidDueDate.bind(this);
    this.saveCarContract = this.saveCarContract.bind(this);
    this.printCarContract = this.printCarContract.bind(this);
    this.loadCarContractByCarAndCustomer = this.loadCarContractByCarAndCustomer.bind(this);
    this.setForCreateContractFromCarBook = this.setForCreateContractFromCarBook.bind(this);
    this.setForCreateContractFromContract = this.setForCreateContractFromContract.bind(this);
    this.loadCompanyLogoBase64 = this.loadCompanyLogoBase64.bind(this);
  }

  async componentDidMount () {
    this.mounted = true;
    const queryString = require('query-string');
    let car_sell = queryString.parse(this.props.location.search);
    //  car_id , customer_id
    let car_sell_id = car_sell.car_sell_id === undefined ? '' : car_sell.car_sell_id ;
    let car_book_id = car_sell.car_book_id === undefined ? '' : car_sell.car_book_id;
    let car_id = car_sell.car_id;
    let customer_id = car_sell.customer_id;

    // console.log('car_id=', typeof car_id, car_id, )
    // console.log('customer_id=', typeof customer_id, customer_id)
    // console.log('car_sell_id=', typeof car_sell_id, car_sell_id)
    // console.log('car_book_id=', typeof car_book_id, car_book_id)

    this.setState({
      car_sell_id: car_sell_id,
      car_book_id: car_book_id,
      car_id: car_id,
      customer_id: customer_id
    }, async () => {
      if (car_sell.car_sell_id === undefined && car_sell.car_book_id !== undefined) {
        // จองแต่ยังไม่ขาย กดจากหน้าการจอง รายคัน
        await this.loadCompanySetting();
        await this.loadCompanyLogoBase64();
        await this.loadCarContractByCarAndCustomer();
        // console.log('car_book_id=', typeof car_book_id, car_book_id)
      } else if (car_sell.car_sell_id !== undefined) {
        // console.log('สัญญาขาย')
        // console.log('car_sell_id=', typeof car_sell_id, car_sell_id)
        // สัญญาขาย กดจากหน้าขาย
        await this.loadCompanySetting();
        await this.loadCompanyLogoBase64();
        await this.loadCarContract(car_sell_id);
        await this.loadCarSellById(car_sell_id);
      }
    })

  }

  async loadCompanyLogoBase64() {
    let uri =  APIURL + '/company_setting/logoimage/1';
    await axios.get(uri)
      .then(res => {
        let body = res.data.body
        this.setState({ logoBase64Img: body })
      }).catch(error => {
      console.log('error: ', error)
    })
  }

  componentWillUnmount() {
    this.mounted = false
    // window.removeEventListener('resize', this.updateWindowDimensions);
  }

  async loadCompanySetting () {
    await axios.get(APIURL + '/company_setting/1')
      .then(res => {
        let data = res.data
        let company_setting = data.company_setting
        if (company_setting !== null) {
          const is_vat = company_setting.is_vat
          const vat = is_vat === 1 ? company_setting.vat : 0
          // console.log(is_vat, vat)
          this.setState({
            company_setting: company_setting,
            seller_name: company_setting.company_name,
            company_address: company_setting.company_address,
            logo_image: company_setting.logo_image,
            vat: vat,
            is_vat: is_vat,
          })
        }
      })
  }

  // โหลดข้อมูลหลังจากบันทึกสัญญาแล้ว
  async loadCarContract (car_sell_id) {
    await axios.get(APIURL + '/contract/car_sell/' + car_sell_id)
      .then(res => {
        let data = res.data
        if(data.length === 1){
          let contract = data[0];
          // let contract_id = contract.contract_id
          // contract_id: contract_id
          let contract_date =  moment(contract.contract_date).format('YYYY-MM-DD')
          let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

          let paid_due_date =  moment(contract.paid_due_date).format('YYYY-MM-DD')
          let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

          let province_name = contract.car_license_plate_new !== '' &&  contract.car_license_plate_new !==  contract.car_license_plate_old ? contract.province_name_new : contract.province_name_old

          this.setState({
            contract_date_thai: contract_date_thai,
            paid_due_date_thai: paid_due_date_thai,

            contract_id : contract.contract_id,
            contract_no : contract.contract_no,
            contract_date : contract.contract_date,
            contract_type : contract.contract_type,
            buyer_address : contract.buyer_address,
            buyer_age : contract.buyer_age,
            buyer_mobile : contract.buyer_mobile,
            buyer_name : contract.buyer_name,
            car_color_name : contract.car_color_name,
            car_id : contract.car_id,
            car_license_plate : contract.car_license_plate,
            car_name : contract.car_name,
            car_num_engines : contract.car_num_engines,
            car_number : contract.car_number,
            car_sell_id : contract.car_sell_id,
            car_year_name : contract.car_year_name,
            card_code : contract.card_code,
            card_out_at : contract.card_out_at,
            card_type : contract.card_type,
            cash_pledge : contract.cash_pledge,
            customer_id : contract.customer_id,
            paid_due_date : contract.paid_due_date,
            //
            real_sell_price : contract.real_sell_price,
            vat : contract.vat,
            vat_amount: contract.vat_amount,
            total_amount: contract.total_amount,
            //
            seller_name : contract.seller_name,
            write_at : contract.write_at,
            note : contract.note,
            province_name : province_name,
            //
            buyer_mobile2: contract.buyer_mobile2,
            buyer_idline: contract.buyer_idline,
            insurance_mobile: contract.insurance_mobile,
            insurance_received_number: contract.insurance_received_number,
            document_contact_mobile: contract.document_contact_mobile,
            check_fee: contract.check_fee,
            car_miles: contract.car_miles,
          });

        }
      })
  }

  async loadCarContractByCarAndCustomer() {

    const {car_id, customer_id, car_book_id} = this.state
    console.log('car_book_id=', car_book_id, typeof car_book_id)
    if (car_book_id === undefined) {
      return
    }

    let url = APIURL+'/contract/by/'+car_id+'/'+customer_id+'/'+car_book_id

    await axios.get(url)
      .then(res => {
        let data = res.data
        let isExistContract = data.isExistContract
        if (isExistContract === true) {
          // มีสัญญาแล้ว
          let contract = data.rows.length === 1 ? data.rows[0] : {}
          // console.log('มีสัญญาแล้ว : isExistContract=', isExistContract)
          // console.log(contract)
          this.setState({
            isExistContract: isExistContract
          }, () => this.setForCreateContractFromContract(contract))
        } else {
          let car_book = data.rows.length === 1 ? data.rows[0] : {}
          // console.log('ยังไม่มีสัญญาแล้ว : isExistContract=', isExistContract)
          // console.log(car_book)
          this.setState({
            isExistContract: isExistContract
          }, () => this.setForCreateContractFromCarBook(car_book))

        }
      })
  }

  // ข้อมูลตั้งต้น ตอนยังไม่กดบันทึกสัญญา
  setForCreateContractFromCarBook(car_book) {
    const { is_vat, vat } = this.state
    let car_name = car_book.car_name
    let car_license_plate = car_book.car_license_plate_new !== '' &&  car_book.car_license_plate_new !==  car_book.car_license_plate_old ? car_book.car_license_plate_new : car_book.car_license_plate_old

    // console.log(typeof car_book.car_num_engines, car_book.car_num_engines, car_book.car_num_engines.length )

    let car_num_engines = car_book.car_num_engines.length > 0 ? car_book.car_num_engines.toUpperCase() : ''// เลขเครื่องยนต์
    let car_number = car_book.car_number.length > 0 ?  car_book.car_number.toUpperCase() :  '' // เลขตัวรถ
    let car_year_name = car_book.car_year_name // ปี
    let car_color_name = car_book.car_color_name // สี

    let customer_lastname = car_book.customer_lastname
    let buyer_name = car_book.customer_name +' ' + customer_lastname // ผู้ซื้อ
    let buyer_age = car_book.customer_age // ผู้ซื้อ
    let buyer_address = car_book.customer_address // ผู้ซื้อ
    let buyer_mobile = car_book.customer_mobile // ผู้ซื้อ
    let card_code = car_book.customer_card_id // ผู้ซื้อ

    let real_sell_price = car_book.cost_car_book
    let car_id = car_book.car_id
    let customer_id = car_book.customer_id
    let car_miles = car_book.car_miles

    // console.log('car_miles=', car_miles)

    let vat_amount = 0

    // console.log('is_vat=', is_vat)
    // console.log('vat=', vat)

    if (is_vat === 1) {
      let vat_amount_cal = real_sell_price * (vat/100);
      vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
    }

    let total_amount = Number(real_sell_price) + Number(vat_amount)
    let province_name = car_book.car_license_plate_new !== '' &&  car_book.car_license_plate_new !==  car_book.car_license_plate_old ? car_book.province_name_new : car_book.province_name_old

    this.setState({
      car_name,
      car_license_plate,
      car_num_engines,
      car_number,
      car_year_name,
      car_color_name,
      buyer_name,
      buyer_age,
      buyer_address,
      buyer_mobile,
      card_code,
      real_sell_price,
      car_id,
      customer_id,
      province_name,
      vat_amount,
      total_amount,
      car_miles
    }, () => {
       // this.loadDataCarSellPay(car_sell, car_id, customer_id)
    })
  }

  setForCreateContractFromContract(contract) {
    let contract_date =  moment(contract.contract_date).format('YYYY-MM-DD')
    let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

    let paid_due_date =  moment(contract.paid_due_date).format('YYYY-MM-DD')
    let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

    let province_name = contract.car_license_plate_new !== '' &&  contract.car_license_plate_new !==  contract.car_license_plate_old ? contract.province_name_new : contract.province_name_old

    let car_miles = contract.car_miles

    this.setState({
      contract_date_thai: contract_date_thai,
      paid_due_date_thai: paid_due_date_thai,

      contract_id : contract.contract_id,
      contract_no : contract.contract_no,
      contract_date : contract.contract_date,
      contract_type : contract.contract_type,
      buyer_address : contract.buyer_address,
      buyer_age : contract.buyer_age,
      buyer_mobile : contract.buyer_mobile,
      buyer_name : contract.buyer_name,
      car_color_name : contract.car_color_name,
      car_id : contract.car_id,
      car_license_plate : contract.car_license_plate,
      car_name : contract.car_name,
      car_num_engines : contract.car_num_engines,
      car_number : contract.car_number,
      car_sell_id : contract.car_sell_id,
      car_year_name : contract.car_year_name,
      card_code : contract.card_code,
      card_out_at : contract.card_out_at,
      card_type : contract.card_type,
      cash_pledge : contract.cash_pledge,
      customer_id : contract.customer_id,
      paid_due_date : contract.paid_due_date,
      //
      real_sell_price : contract.real_sell_price,
      vat : contract.vat,
      vat_amount: contract.vat_amount,
      total_amount: contract.total_amount,
      //
      seller_name : contract.seller_name,
      write_at : contract.write_at,
      note : contract.note,
      province_name : province_name,
      //
      buyer_mobile2: contract.buyer_mobile2,
      buyer_idline: contract.buyer_idline,
      insurance_mobile: contract.insurance_mobile,
      insurance_received_number: contract.insurance_received_number,
      document_contact_mobile: contract.document_contact_mobile,
      check_fee: contract.check_fee,
      car_miles
    });
  }

  async loadCarSellById (car_sell_id) {
    if (this.state.contract_id !== null) {
      // มีรายการสัญญาแล้ว ไม่ต้องโหลดข้อมูล
      return
    }

    const { is_vat, vat } = this.state

    // โหลดข้อมูลตั้งต้น ตอนยังไม่กดบันทึกสัญญา
    await axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if(this.mounted) {
          let car_sell = res.data[0]
          let car_sell_date = car_sell.car_sell_date
          let date = new Date(car_sell_date)

          let car_name = car_sell.car_name
          let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
          let car_num_engines = car_sell.car_num_engines // เลขเครื่องยนต์
          let car_number = car_sell.car_number // เลขตัวรถ
          let car_year_name = car_sell.car_year_name // ปี
          let car_color_name = car_sell.car_color_name // สี

          let customer_lastname = car_sell.customer_lastname
          let buyer_name = car_sell.customer_name +' ' + customer_lastname // ผู้ซื้อ
          let buyer_age = car_sell.customer_age // ผู้ซื้อ
          let buyer_address = car_sell.customer_address // ผู้ซื้อ
          let buyer_mobile = car_sell.customer_mobile // ผู้ซื้อ
          let card_code = car_sell.customer_card_id // ผู้ซื้อ

          let real_sell_price = car_sell.real_sell_price
          let car_id = car_sell.car_id
          let customer_id = car_sell.customer_id

          let vat_amount = 0

          if (is_vat === 1) {
             let vat_amount_cal = real_sell_price * (vat/100);
             vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
          }

          let total_amount = Number(real_sell_price) + Number(vat_amount)

          let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

          this.setState({
            car_name,
            car_license_plate,
            car_num_engines,
            car_number,
            car_year_name,
            car_color_name,
            buyer_name,
            buyer_age,
            buyer_address,
            buyer_mobile,
            card_code,
            real_sell_price,
            car_id,
            customer_id,
            province_name,
            car_sell_id,
            vat_amount,
            total_amount
          }, () => {
            this.loadDataCarSellPay(car_sell, car_id, customer_id)
          })
        }
      }).catch(error => {
        console.log ('error:', error)
      })
  }

  handleDateContract (date) {
    let contract_date =  moment(date).format('YYYY-MM-DD')
    let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

    this.setState({
      startDateContract: date,
      isOpenDateContract: false,
      contract_date: contract_date,
      contract_date_thai: contract_date_thai
    })
  }

  handlePaidDueDate (date) {
    let paid_due_date =  moment(date).format('YYYY-MM-DD')
    let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

    this.setState({
      startPaidDueDate: date,
      isOpenPaidDueDate: false,
      paid_due_date: paid_due_date,
      paid_due_date_thai: paid_due_date_thai
    })
  }

  handleOnChange(e) {
    let name = e.target.name;
    let val = e.target.value;

    this.setState({
      [name]: val
    })
  }

  calOutstanding() {
    const { is_vat, vat } = this.state

    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge

    // let vat_amount = this.state.vat_amount
    let vat_amount = 0

    /*if (vat_amount !== '0') {
        if (is_vat === 1) {
          let vat_amount_cal = real_sell_price * (vat / 100);
          vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
        }
    }*/

    let total_amount = Number(real_sell_price) + Number(vat_amount)
    let outstanding = Number(total_amount) - cash_pledge

    this.setState({
      outstanding: outstanding,
      vat_amount,
      total_amount
    })
  }

  loadDataCarSellPay (car_sell, car_id, customer_id) {

    if (car_id === '' || customer_id === '') {
      return
    }

    axios.get(APIURL + '/car_sell_pay/buy/' +car_id + '/' + customer_id)
      .then(res => {
        let car_sell_pay_list = res.data
        let sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)

        let real_sell_price = this.state.real_sell_price
        let outstanding = Number(real_sell_price) - sum_pay

        this.setState({
          cash_pledge: sum_pay,
          outstanding: outstanding
        })

      }).catch(error => {
      console.log(error)
    })
  }

  async saveCarContract(action) {

    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines
    let car_number = this.state.car_number
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at
    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai

    let note = this.state.note

    /*if (contract_no === '') {
      AlertSuccess('กรุณากรอกเลขสัญญา');
      return
    }*/

    if(write_at === '') {
      AlertSuccess('กรุณากรอกเขียนที่');
      return
    }

    if (contract_date_thai === null) {
      AlertSuccess('กรุณาเลือกวันที่ทำสัญญา');
      return
    }

    if (paid_due_date_thai === null) {
      AlertSuccess('กรุณาเลือกวันที่ครบกำหนดชำระ');
      return
    }

    // vat , vat_amount, total_amount
    let vat = this.state.vat
    let vat_amount = this.state.vat_amount
    let total_amount = this.state.total_amount

    if (vat_amount === '0' || vat_amount===0) {
      vat = 0
    }

    let buyer_mobile2 = this.state.buyer_mobile2
    let buyer_idline = this.state.buyer_idline
    let insurance_mobile = this.state.insurance_mobile
    let insurance_received_number = this.state.insurance_received_number
    let document_contact_mobile = this.state.document_contact_mobile
    let check_fee = this.state.check_fee

    let data = {
      contract_no : contract_no,
      write_at : write_at,
      contract_date : contract_date,
      seller_name : seller_name,
      car_name : car_name,
      car_license_plate : car_license_plate,
      car_num_engines : car_num_engines,
      car_number : car_number,
      car_year_name : car_year_name,
      car_color_name : car_color_name,
      buyer_name : buyer_name,
      buyer_age : buyer_age,
      buyer_address : buyer_address,
      buyer_mobile : buyer_mobile,
      card_type : card_type,
      card_code : card_code,
      card_out_at : card_out_at,
      real_sell_price : real_sell_price,
      cash_pledge : cash_pledge,
      paid_due_date : paid_due_date,
      car_id : car_id,
      customer_id : customer_id,
      car_sell_id : car_sell_id,
      contract_type : contract_type,
      note : note,
      vat,
      vat_amount,
      total_amount,
      buyer_mobile2,
      buyer_idline,
      insurance_mobile,
      insurance_received_number,
      document_contact_mobile,
      check_fee
    }

    this.setState({
      isLoading: true,
    }, () => {
      if (action === 'add') {
        let url = APIURL + '/contract';
        axios.post( url, data)
          .then( res => {
            if (res.status === 200) {
              let resData = res.data
              let contract_id = resData.contract_id;
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
                contract_id: contract_id
                // isExist: true,
              }, () => {
                if (car_sell_id !== '') {
                  this.loadCarContract(car_sell_id);
                } else {
                  this.loadCarContractByCarAndCustomer()
                }
              })
            }
          }).catch(error => {
          console.log('error:', error)
        });
      } else if (action === 'edit') {
        let contract_id = this.state.contract_id
        let editData = {...data, contract_id: contract_id }

        let url = APIURL + '/contract';
        axios.put( url, editData)
          .then(res => {
            if (res.data === 'success') {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
              }, () => {
                console.log('contract_id=', contract_id, typeof contract_id)
                if (contract_id === null) {
                  this.loadCarContractByCarAndCustomer()
                }  else {
                  this.loadCarContract(car_sell_id)
                }

              })
            }
          }).catch(error => {
          console.log('error:', error)
        });
      }

    });

  }

  printCarContract() {
    let company_setting = this.state.company_setting
    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines === 0 ? '-' : String(this.state.car_num_engines).toUpperCase()
    let car_number = this.state.car_number.length === 0 ? '-' : String(this.state.car_number).toUpperCase()
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile === '' ? '..................' : this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at
    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai
    // let outstanding =  real_sell_price - cash_pledge
    let outstanding =  this.state.outstanding

    let note = this.state.note
    let province_name = String(this.state.province_name).trim()
    let vat = this.state.vat === 0 ? (this.state.company_setting !== null ? this.state.company_setting.vat : '7') : this.state.vat
    let vat_amount = this.state.vat_amount
    let total_amount = this.state.total_amount

    let company_address = company_setting.company_address
    let buyer_mobile2 = this.state.buyer_mobile2 === '' ? '..................' : this.state.buyer_mobile2
    let buyer_idline = this.state.buyer_idline === '' ? '..................' : this.state.buyer_idline
    let insurance_mobile = this.state.insurance_mobile === '' ? '..................' : this.state.insurance_mobile
    let insurance_received_number = this.state.insurance_received_number === '' ? '..................' : this.state.insurance_received_number
    let document_contact_mobile = this.state.document_contact_mobile === '' ? '..................' : this.state.document_contact_mobile
    let check_fee = this.state.check_fee === 0 ? '......-......' : NumberUtil.addCommas(this.state.check_fee)
    let logoBase64Img = this.state.logoBase64Img
    let car_miles = this.state.car_miles === 0 || this.state.car_miles === '0' || this.state.car_miles === ''? '......-......' : NumberUtil.addCommas(this.state.car_miles)

    if (logoBase64Img === '') {
      AlertWarning('ไม่พบไฟล์ logo');
      return
    }

    let headerLeft
    let logo_width = 80

    headerLeft = {
      image: logoBase64Img, width: logo_width
    }

    let tableHeader = {
        table: {
          widths: [ '30%', '40%', '30%' ],
          body: [
            [
              headerLeft,
              {
                text: 'สัญญาขายรถยนต์',
                style: 'bodyhead'
              },
              {
                table: {
                  widths: ['100%'],
                  body: [
                    [{text: 'เขียนที่ ' + write_at, style: 'normalRight',  margin: [0,8,0,0]}, ],
                    [{text: 'วันที่ ' + contract_date_thai, style: 'normalRight', margin: [0,0,0,0]}]
                  ]
                },
                layout: 'noBorders',
              },
            ],
          ],
        },
        layout: 'noBorders',
      }


    let line_car = 'ยี่ห้อ................................' + car_name +'................................ทะเบียน........' + car_license_plate + '......' + province_name+'.......';
    // console.log('line_car=', line_car.length)
    // text: 'ยี่ห้อ............' + car_name +'.............ทะเบียน..........' + car_license_plate + '....' + province_name+'.....' ,
    if (line_car.length > 169) {
      line_car = 'ยี่ห้อ.....................' + car_name +'.....................ทะเบียน........' + car_license_plate + '......' + province_name+'.......';
    }

    let blockCompanyAndCar = [
      {
        columns: [
          { width: '5%',
            text: ' ', style: 'normal',
          },
          { width: '95%', style: 'normal',
            text: seller_name +' '+ company_address+' ตกลงขายรถยนต์',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          { width: '100%', style: 'normal',
            text: line_car ,
          },
        ],
        columnGap: 0,
        lineHeight: 1
      },
      {
        columns: [
          { width: '100%', style: 'normal',
            text: 'หมายเลขตัวถัง...' + car_number+ '.....หมายเลขเครื่องยนต์...' + car_num_engines+ '.....ปี...'+car_year_name+'.....สี...'+car_color_name+'...',
          },
        ],
        columnGap: 0,
        lineHeight: 1.5
      },
    ]

    let blockBuyer = [
        {
          columns: [
            { width: '5%',
              text: '', style: 'normal',
            },
            { width: '95%', style: 'normal',
              text: 'ขายให้แก่.............'+ buyer_name + '.............อายุ........'+ buyer_age + '........ปี' + '......ที่อยู่.......'+buyer_address+'.......'
            },
          ],
          columnGap: 0,
          lineHeight: 1
        },
        {
          columns: [
            { width: '100%', style: 'normal',
              text: 'โทรศัพท์ 1).........' + buyer_mobile + '.....................2).........'+ buyer_mobile2+'.....................Id line.........'+buyer_idline+'..............',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: 'บัตร...........'+card_type+'...........หมายเลข...........'+ card_code +'...........ออกให้ ณ...........'+ card_out_at + '...........',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
    ]


    /*{
      columns: [
        { width: '30%',
          text: ' ภาษีมูลค่าเพิ่ม '+String(vat)+'%', style: 'normal', marginTop: 0
        },
        { width: '20%', style: 'normalCenter', marginTop: 0,
          text: NumberUtil.addCommas(parseInt(vat_amount, 10)),
        },
        { width: '5%', style: 'normal',marginTop: 0,
          text: 'บาท',
        },
        { width: '45%', style: 'normal', marginTop: 0,
          text: '('+ThaiBaht(vat_amount)+')',
        },
      ],
        columnGap: 0,
      lineHeight:1
    },
    {
          columns: [
            { width: '30%',
              text: ' รวมจำนวนเงินทั้งสิ้น', style: 'normal', marginTop: 0
            },
            { width: '20%', style: 'normalCenter', marginTop: 0,
              text: NumberUtil.addCommas(parseInt(total_amount, 10)),
            },
            { width: '5%', style: 'normal',marginTop: 0,
              text: 'บาท',
            },
            { width: '45%', style: 'normal', marginTop: 0,
              text: '('+ThaiBaht(total_amount)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
    */

    let blockPrices = [
      {
        columns: [
          { width: '30%',
            text: ' เป็นจำนวนเงินทั้งสิ้น', style: 'normal', marginTop: 8
          },
          { width: '20%', style: 'normalCenter', marginTop: 8,
            text: NumberUtil.addCommas(parseInt(real_sell_price, 10)),
          },
          { width: '5%', style: 'normal',marginTop: 8,
            text: 'บาท',
          },
          { width: '45%', style: 'normal', marginTop: 8,
            text: '('+ThaiBaht(real_sell_price)+')',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          { width: '30%',
            text: ' ข้าพเจ้าได้รับเงินมัดจำไว้เป็นเงิน', style: 'normal',
          },
          { width: '20%', style: 'normalCenter',
            text: NumberUtil.addCommas(parseInt(cash_pledge, 10)),
          },
          { width: '5%', style: 'normal',
            text: 'บาท',
          },
          { width: '45%', style: 'normal',
            text: '('+ThaiBaht(cash_pledge)+')',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          { width: '30%',
            text: ' ผู้ซื้อยังคงค้างอีกเป็นจำนวนเงิน', style: 'normal',
          },
          { width: '20%', style: 'normalCenter',
            text: NumberUtil.addCommas(parseInt(outstanding, 10)),
          },
          { width: '5%', style: 'normal',
            text: 'บาท',
          },
          { width: '45%', style: 'normal',
            text: '('+ThaiBaht(outstanding)+')',
          },
        ],
        columnGap: 0,
        lineHeight: 1
      },
      {
        columns: [
          {
            width: '100%',
            text: 'ผู้ซื้อจะนำเงินมาชำระให้ครบจำนวนตามที่ตกลง ภายใน..........วันที่ '+ paid_due_date_thai+'..........', style: 'normal',
          }
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 4, 0, 0]
      },
    ]

    let blockWarningBuyer = [
      {
        columns: [
          { width: '5%',
            text: '', style: 'normal',
          },
          { width: '95%', style: 'normal',
            text: 'หากผู้ซื้อไม่นำเงินมาชำระให้ตามกำหนด ผู้ซื้อยินยอมให้ยึดเงินมัดจำและคืนรถในทันทีในสภาพเรียบร้อยทุกประการ ถ้าหากเกิดความเสียหายใดๆ ก็ดี '
          },
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 4, 0, 0]
      },
      {
        stack: [
          {text: [
              'ผู้ซื้อยินยอมชดใช้ให้ทั้งสิ้น ตลอดทั้งคดีแพ่ง และคดีอาญา ส่วนผู้ขายเมื่อได้รับเงินครบถ้วนตามสัญญาแล้ว ผู้ขายจะทำการโอนทะเบียนยานพาหนะฯ ผู้ซื้อ และผู้ขายได้อ่านข้อความเข้าใจดีแล้ว และตกลงกันทั้งสองฝ่าย จึงได้ลงลายมือชื่อไว้ เป็นหลักฐานต่อหน้าพยานจำนวนเงินที่จองผู้เช่าซื้อได้ชำระให้กับผู้ขายแล้ว ถ้าสถาบันการเงินไม่อนุมัติตามที่ตกลง ผู้ขายจะ',
              {text: 'คืนเงิน', bold: true},
              'ให้กับผู้ซื้อทุกกรณีตาม เงื่อนไขที่ตกลงกันไว้',
            ]
          }
        ],
        style: 'normal'
      }
    ]


    let blockExcept = [
      {
        columns: [
          {
            width: '5%',
            text: 'ยกเว้น',
            style: ['normalBold', 'underline'],
          },
          {
            width: '65%',
            stack: [
              {text: [
                  '1.เมื่อสถาบันการเงินอนุมัติแล้ว แต่ผู้ซื้อ',
                  {text: 'ไม่ยอมรับรถ', bold: true},
                  ' ตามกำหนด',
                ],
              }
            ],
            style: ['normal', 'underline']
          },
          {
            width: '30%',
            text: '',
            style: 'normal',
          },
        ],
        margin: [0, 4, 0, 0]
      },
      {
        columns: [
          {
            width: '5%',
            text: '',
            style: ['normalBold', 'underline'],
          },
          {
            width: '65%',
            stack: [
              {text: [
                  '2.จองแล้ว',
                  {text: 'ไม่จัดไฟแนนซ์', bold: true},
                  'และไม่ให้ความร่วมมือในการจัดไฟแนนท์',
                ],
              }
            ],
            style: ['normal', 'underline']
          },
          {
            width: '30%',
            text: 'ค่าตรวจสอบ.............'+check_fee+'..............บาท',
            style: 'normalRight',
          },
        ],
      }
    ]

    let blockSellerResponsibility = [
      {
        columns: [
          {
            width: '100%',
            style: ['normal', 'underline'],
            text: 'ความรับผิดชอบของผู้ขายดังนี้',
          },
        ],
        columnGap: 0,
        lineHeight:1,
        margin: [0, 4, 0, 0]
      },
      {
        columns: [
          {
            width: '100%',
            style: 'normal',
            text: '1.รับซื้อรถคืน 100%',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          {
            width: '5%',
            style: 'normal',
            text: '',
          },
          {
            width: '95%',
            style: 'normal',
            text: '1.1 รถชนหนัก พลิกคว่ำ หรือน้ำท่วม',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          {
            width: '5%',
            style: 'normal',
            text: '',
          },
          {
            width: '95%',
            style: 'normal',
            text: '1.2 ไม่สามารถโอนเล่มทะเบียน เป็นกรรมสิทธิ์ของผู้ซื้อได้',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          {
            width: '5%',
            style: 'normal',
            text: '',
          },
          {
            width: '95%',
            style: 'normal',
            text: '1.3 ลูกค้าพร้อมรับรถ...................................................',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          {
            width: '5%',
            style: 'normal',
            text: '',
          },
          {
            width: '95%',
            style: 'normal',
            text: '1.4 รถตีเทิร์น ยี่ห้อ........................................ รุ่น...............................ทะเบียน...............................ปี................. เลขไมล์.....................ราคา......................',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          {
            width: '5%',
            style: 'normal',
            text: '',
          },
          {
            width: '95%',
            style: 'normal',
            text: '(1.1-1.2 รับซื้อภายใน 7 วัน ตั้งแต่วันที่รับรถไป)',
          },
        ],
        columnGap: 0,
        lineHeight:1
      },
      {
        columns: [
          {
            width: '100%',
            style: ['normal'],
            text: '2.รับประกัน เครื่องยนต์ ช่วงล่าง ระบบแอร์ (ฟรีค่าแรงค่าอะไหล่) ภายในเวลา 1 ปี หรือ 10,000 กิโลเมตร แล้วแต่ระยะไหนถึงก่อนนับแต่วันที่ออกรถไป และเป็นไปตามปกติของการใช้งานทั่วไป (ต้องนำรถยนต์มารับบริการที่ ศูนย์บริการ บริษัท เอ.ที.คาร์เซลส์ จำกัด เท่านั้น)',
          },
        ],
        columnGap: 0,
        lineHeight:1,
        margin: [0, 0, 0, 0]
      },
      {
        columns: [
          {
            width: '100%',
            style: ['normal'],
            text: '3.รับประกัน เกียร์ เฟื่องท้าย (ฟรีค่าแรงค่าอะไหล่) ภายในเวลา 1 ปี หรือ 10,000 กิโลเมตร และรถที่มีอายุ เกิน 7 ปี ขึ้นไป รับประกัน 6 เดือน หรือ 5,000 กิโลเมตรแล้วแต่ระยะไหนถึงก่อนนับแต่วันที่ออกรถไป และเป็นไปตามปกติของการใช้งานทั่วไป (ต้องนำรถยนต์มารับบริการที่ ศูนย์บริการ บริษัท เอ.ที.คาร์เซลส์ จำกัด เท่านั้น)',
          },
        ],
        columnGap: 0,
        lineHeight:1,
        margin: [0, 0, 0, 0]
      },
      {
        columns: [
          {
            width: '100%',
            style: ['normal'],
            text: '** รถที่มีอายุ เกิน 10 ปี ไม่มีรับประกันหลังการขาย**',
          },
        ],
        columnGap: 0,
        lineHeight:1,
        margin: [0, 0, 0, 0]
      },
      {
        columns: [
          {
            width: '100%',
            style: ['normal'],
            text: '4. เงื่อนไขการรับประกัน เป็นไปตามบันทึกข้อตกลงเงื่อนไขการรับประกันของบริษัทเท่านั้น',
          },
        ],
        columnGap: 0,
        lineHeight:1,
        margin: [0, 0, 0, 0]
      },
    ]

    let blockSign = [
      {
        columns: [
          {
            width: '45%',
            style: 'normal',
            text: 'ลงนาม.............................................................................(ผู้ขาย)'
          },
          {
            width: '5%',
            style: 'normal',
            text: ''
          },
          {
            width: '50%',
            style: 'normal',
            text: 'ลงนาม.............................................................................(ผู้ซื้อ)'
          }
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 10, 0, 0]
      },
      {
        columns: [
          {
            width: '45%',
            style: 'normal',
            text: 'เบอร์โทรศัพท์..................................................................(ผู้ขาย)'
          },
          {
            width: '5%',
            style: 'normal',
            text: ''
          },
          {
            width: '50%',
            style: 'normal',
            text: 'เบอร์โทรศัพท์..................................................................(ผู้ซื้อ)'
          }
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 0, 0, 0]
      },
    ]

    let blockReceiveCar = [
      {
        columns: [
          {
            width: '100%',
            style: ['normal'],
            text: 'ข้าพเจ้าผู้ซื้อ..............'+buyer_name+'..............ได้รับรถยนต์หมายเลขทะเบียน...........'+car_license_plate+'........'+province_name+'........เลขไมล์......'+car_miles+'......',
          },
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 4, 0, 0]
      },
      {
        columns: [
          {
            width: '100%',
            style: 'normal',
            text: 'ไว้ในความครอบครองเมื่อวันที่...................................................................................... ได้ตรวจสอบเลขไมล์ เลขเครื่องยนต์ และเลขตัวถัง ตามจริงและยินยอมรับรถ'
          }
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 0, 0, 0]
      },
      {
        columns: [
          {
            width: '100%',
            style: 'normal',
            text: 'ตามสภาพ ทุกประการ หากเกิดการผิดพลาดข้อมูลไม่ตรง จะไม่เรียกร้องค่าเสียหายใด ๆ จากบริษัท ในภายหลัง'
          }
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 0, 0, 0]
      },
    ]



    let blockNote = [
      {
        columns: [
          {
            width: '100%',
            style: 'normal',
            text: note === '' || note === null ? 'หมายเหตุ ______________________________________________________________________________________'
              :
              'หมายเหตุ ' + ' ' + note
          },
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 0, 0, 0,]
      },
      /*{
        columns: [
          {
            width: '100%',
            style: 'normal',
            text: note === '' || note === null ? '______________________________________________________________________________________________'
              : ' '
          },
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 0, 0, 0,]
      }*/
    ]


    let blockReceiveSign = [
      {
        columns: [
          {
            width: '45%',
            style: 'normal',
            text: 'ลงนาม.............................................................................(ผู้ขาย)'
          },
          {
            width: '5%',
            style: 'normal',
            text: ''
          },
          {
            width: '50%',
            style: 'normal',
            text: 'ลงนาม.............................................................................(ผู้รับรถ)'
          }
        ],
        columnGap: 0,
        lineHeight: 1,
        margin: [0, 4, 0, 14]
      },
    ]

    let blockFooter = {
        table: {
          widths: [ '*' ],
          body: [
            [
              {
                columns: [
                  {
                    width: '100%',
                    style: 'normal',
                    text: 'เบอร์โทรศัพท์สำหรับติดต่อประกัน...........'+insurance_mobile+'...........เลขที่รับแจ้ง...........'+insurance_received_number+'...........'
                  }
                ],
                margin: [8, 8, 8, 0,],
                border: [true, true, true, false],
                // fillColor: '#cccccc',
              },
            ],
            [
              {
                columns: [
                  {
                    width: '100%',
                    style: 'normal',
                    text: 'เบอร์โทรศัพท์สำหรับการติดต่อตามเอกสาร......................'+document_contact_mobile+'......................'
                  },
                ],
                margin: [8, 0, 8, 0,],
                border: [true, false, true, false],
              }
            ],
            [
              {
                columns: [
                  {
                    width: '100%',
                    style: 'normal',
                    text: 'หมายเหตุ (1.)สัญญาเช่าซื้อ (2.)สำเนาทะเบียนรถ (3.)เอกสารประกันชั้น (4.)เอกสารประกันชั้น1 (5.)เอกสารประกันอุบัติเหตุส่วน'
                  },
                ],
                margin: [8, 0, 8, 8,],
                border: [true, false, true, true],
              }
            ],
          ]
        },

      }
      // layout: 'noBorders'
    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 20, 35, 0],
      content: [
        tableHeader,
        blockCompanyAndCar,
        blockBuyer,
        blockPrices,
        blockWarningBuyer,
        blockExcept,
        blockSellerResponsibility,
        blockSign,
        blockReceiveCar,
        blockNote,
        blockReceiveSign,
        blockFooter
      ],
      styles: {
        bodyhead: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        normal: {
          fontSize: 12,
        },
        normalBold: {
          fontSize: 12,
          bold: true
        },
        underline: {
          decoration: 'underline'
        },
        normalRight: {
          fontSize: 12,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 12,
          alignment: 'left',
        },
        normalCenter: {
          fontSize: 12,
          alignment: 'center',
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    pdfMake.createPdf(docDefinition).open();

  }

  render() {
    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-12">
          <div className="card">

            <div className="card-header">
              <strong className="text-title">สัญญาขายรถยนต์</strong>
            </div>

            <div className="card-block" style={{paddingLeft: 80, paddingRight: 80, paddingTop: 10}} >

              <div className="row" style={{lineHeight: 2.0}}>

                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td width={'30%'} height="20px" style={styles.tdContentCenter} >
                        &nbsp;
                      </td>
                      <td style={styles.tdContentCenter}>
                        <strong style={{fontSize: 18}}> สัญญาขายรถยนต์ </strong>
                      </td>
                      <td width={'30%'}>&nbsp;</td>
                    </tr>

                    <tr  style={{height: '80px'}}>
                      <td style={{height: '80px' }}  height="80px" >
                        <div className="form-inline mt-0 mb-0">
                          {/*No.
                          <input className="form-control ml-4"
                                 style={styles.inputDataNum}
                                 value={this.state.contract_no}
                                 onChange={(e) => {
                                   this.setState({
                                     contract_no: e.target.value
                                   })
                                 }}
                          />*/}
                          {
                            this.state.logo_image === '' ? null : (
                              <>
                               <img src={this.state.logo_image} height="80px"  alt="car"/>
                              </>
                            )
                          }

                        </div>
                      </td>
                      <td style={{height: '80px' }} height="80px" >&nbsp;</td>
                      <td style={{height: '80px'}} height="80px" >

                        <div className="form-inline mt-0 mb-0" style={{justifyContent: 'right' }}>
                          เขียนที่ <input className="form-control ml-4"
                                        style={styles.inputDataWriteAt}
                                        value={this.state.write_at}
                                        onChange={(e) => {
                                          this.setState({
                                            write_at: e.target.value
                                          })
                                        }}
                        />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  > &nbsp; </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight} >
                        <div className="mb-0 row" style={{justifyContent: 'right' }}>
                          วันที่ &nbsp;
                          <input readOnly={true}
                                 className="form-control"
                                 style={{width: 152}}
                                 value={this.state.contract_date_thai === null ? '' : this.state.contract_date_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>
                          {
                            this.state.isOpenDateContract && (
                              <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                inline
                              />
                            )
                          }
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-1">
                          <span style={{width: 50, textAlign: 'left'}} >&nbsp;</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="seller_name"
                                 value={this.state.seller_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-2"
                                 style={{...styles.inputLong, width: '450px'}}
                                 name="company_address"
                                 value={this.state.company_address}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{width: 130, textAlign: 'left'}} >ตกลงขายรถ</span>

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 100, textAlign: 'left'}} >ยี่ห้อ</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputLong, width: '390px'}}
                                 name="car_name"
                                 value={this.state.car_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{width: 80, textAlign: 'right'}} >ทะเบียน</span>
                          <input className="form-control ml-1"
                                 style={{width: 140, borderRadius: '5px'}}
                                 name="car_license_plate"
                                 value={this.state.car_license_plate}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          <input className="form-control ml-2"
                                 style={{width: 150, borderRadius: '5px'}}
                                 name="province_name"
                                 disabled
                                 value={this.state.province_name}
                            //onChange={this.handleOnChange}
                          />
                          {/*<input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="car_num_engines"
                                 value={this.state.car_num_engines}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}

                          {/*<span style={{width: 130}}  >หมายเลขตัวรถ</span>
                          <input className="form-control ml-1"
                                 style={styles.inputLong}
                                 name="car_number"
                                 value={this.state.car_number}
                                 onChange={this.handleOnChange}
                          />*/}

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 100}}  >หมายเลขตัวถัง</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputLong,  width: '190px'}}
                                 name="car_number"
                                 value={this.state.car_number.length === 0 ? '-' : String(this.state.car_number).toUpperCase()}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{width: 120, textAlign: 'right'}} >หมายเลขเครื่องยนต์&nbsp;</span>
                          <input className="form-control ml-0"
                                 style={{...styles.inputLong,  width: '170px'}}
                                 name="car_num_engines"
                                 value={this.state.car_num_engines.length === 0 ? '-' : String(this.state.car_num_engines).toUpperCase()}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{width: 20, textAlign: 'right'}}>ปี</span>&nbsp;&nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-0"
                                 style={{...styles.inputShort, width: '65px'}}
                                 name="car_year_name"
                                 value={this.state.car_year_name}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={4}
                          />
                          &nbsp;&nbsp;
                          <span style={{width: 20, textAlign: 'right'}}>สี</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-1"
                                 style={{...styles.inputShort, width: '100px'}}
                                 name="car_color_name"
                                 value={this.state.car_color_name}
                                 onChange={this.handleOnChange}
                          />

                        </div>
                      </td>
                    </tr>

                    {/*<tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                         <span style={{width: 130}} >หมายเลขทะเบียน</span>
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="car_license_plate"
                                 value={this.state.car_license_plate}
                                 onChange={this.handleOnChange}
                          />
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="province_name"
                                 disabled
                                 value={this.state.province_name}
                                 //onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        </div>
                      </td>
                    </tr>*/}

                    {/*{ขายให้แก่}*/}
                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-1">
                          <span style={{width: 100, textAlign: 'right'}} >ขายให้แก่</span>
                          <input className="form-control ml-1"
                                 style={styles.inputLong}
                                 name="buyer_name"
                                 value={this.state.buyer_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span style={{width: '30px', textAlign: 'right'}} > อายุ </span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputShort, width: '60px'}}
                                 name="buyer_age"
                                 value={this.state.buyer_age}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={2}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span style={{width: '30px', textAlign: 'left'}} >ที่อยู่</span>
                          <input className="form-control ml-1"
                                 style={{width: '45%', borderRadius: '5px'}}
                                 name="buyer_address"
                                 value={this.state.buyer_address}
                                 onChange={this.handleOnChange}
                          />

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-1">
                          <span style={{width: 100, textAlign: 'left'}} >โทรศัพท์ 1)</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputShort, width: '150px'}}
                                 name="buyer_mobile"
                                 value={this.state.buyer_mobile}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 90, textAlign: 'left'}} >โทรศัพท์ 2)</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputShort, width: '150px'}}
                                 name="buyer_mobile2"
                                 value={this.state.buyer_mobile2}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 100, textAlign: 'right'}} >Id line</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputShort, width: '150px'}}
                                 name="buyer_idline"
                                 value={this.state.buyer_idline}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-1">
                          <span style={{width: 100}}  >บัตร</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputShort, width: '150px'}}
                                 name="card_type"
                                 value={this.state.card_type}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 90, textAlign: 'center'}}> หมายเลข </span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputShort, width: '150px'}}
                                 name="card_code"
                                 value={this.state.card_code}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 100, textAlign: 'right'}}> ออกให้ ณ เขต</span>
                          <input className="form-control ml-1"
                                 style={{...styles.inputLong, width: '250px'}}
                                 name="card_out_at"
                                 value={this.state.card_out_at}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 250}} >เป็นจำนวนเงินทั้งสิ้น</span>
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.real_sell_price}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({real_sell_price: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-1" >({ThaiBaht(this.state.real_sell_price)})</span>
                        </div>
                      </td>
                    </tr>

                    {/*<tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 250}} >
                            ภาษีมูลค่าเพิ่ม
                            {this.state.vat === 0 ? (this.state.company_setting !== null ? this.state.company_setting.vat : '7') : this.state.vat}%</span>

                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.vat_amount}
                            onValueChange={(values) => {
                              const {value} = values
                              if (this.state.contract_id === null) {
                                this.setState({vat_amount: value}, () => this.calOutstanding())
                              } else {
                                if (value !== '0') {
                                  this.setState({
                                    vat_amount: value,
                                    vat: this.state.company_setting.vat
                                  }, () => this.calOutstanding())
                                } else {
                                  this.setState({
                                    vat_amount: value,
                                    vat: 0
                                  }, () => this.calOutstanding())
                                }
                              }

                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-1" >({ThaiBaht(this.state.vat_amount)})</span>
                        </div>
                      </td>
                    </tr>*/}

                    {/*<tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 250}} >รวมจำนวนเงินทั้งสิ้น</span>
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.total_amount}
                            onValueChange={(values) => {
                              // const {value} = values;
                              // console.log('total_amount=', value);
                              // this.setState({total_amount: value})
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-1" >({ThaiBaht(this.state.total_amount)})</span>
                        </div>
                      </td>
                    </tr>*/}

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 250}} >ข้าพเจ้าได้รับเงินมัดจำไว้เป็นเงิน</span>
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.cash_pledge}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({cash_pledge: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({ThaiBaht(this.state.cash_pledge)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 250}} >ผู้ซื้อยังคงค้างอีกเป็นจำนวนเงิน</span>
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.outstanding}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({outstanding: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({ThaiBaht(this.state.outstanding)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                            <span style={{width: 380}} >ผู้ซื้อจะนำเงินมาชำระให้ครบจำนวนตามที่ตกลง ภายในวันที่</span>
                            <input readOnly={true}
                                   className="form-control ml-3"
                                   name="paid_due_date_thai"
                                   style={{width: 160, height: 38}}
                                   value={this.state.paid_due_date_thai === null ? '' : this.state.paid_due_date_thai}
                            />
                            <div style={styles.iconCalendar}>
                              <DatePicker
                                  selected={this.state.startPaidDueDate}
                                  onChange={this.handlePaidDueDate}
                                  dateFormat="yyyy-MM-dd"
                                  customInput={<DatePickerIcon />}
                              />
                            </div>

                            {
                              this.state.isOpenPaidDueDate && (
                                <DatePicker
                                  selected={this.state.startPaidDueDate}
                                  onChange={this.handlePaidDueDate}
                                  dateFormat="yyyy-MM-dd"
                                  inline
                                />
                              )
                            }

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span >
                            &nbsp;&nbsp;&nbsp;&nbsp; หากผู้ซื้อไม่นำเงินมาชำระให้ตามกำหนด ผู้ซื้อยินยอมให้ยึดเงินมัดจำและคืนรถในทันทีในสภาพเรียบร้อยทุกประการ
                            ถ้าหากเกิดความเสียหายใดๆ ก็ดี ผู้ซื้อยินยอมชดใช้ให้ทั้งสิ้น ตลอดทั้งคดีแพ่ง และคดีอาญา ส่วนผู้ขายเมื่อได้รับเงินครบถ้วนตามสัญญาแล้ว
                            ผู้ขายจะทำการโอนทะเบียนยานพาหนะฯ ผู้ซื้อ และผู้ขายได้อ่านข้อความเข้าใจดีแล้ว และตกลงกันทั้งสองฝ่าย จึงได้ลงลายมือชื่อไว้
                            เป็นหลักฐานต่อหน้าพยานจำนวนเงินที่จองผู้เช่าซื้อได้ชำระให้กับผู้ขายแล้ว ถ้าสถาบันการเงินไม่อนุมัติตามที่ตกลง
                            ผู้ขายจะ<strong>คืนเงิน</strong>ให้กับผู้ซื้อทุกกรณีตาม เงื่อนไขที่ตกลงกันไว้
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <table>
                          <tbody>
                            <tr style={{width: '100%'}} >
                               <td style={{width: '50px'}}>ยกเว้น</td>
                               <td style={{whiteSpace: 'nowrap', width: '600px', minWidth: '400px'}}>1.เมื่อสถาบันการเงินอนุมัติแล้ว แต่ผู้ซื้อ<strong>ไม่ยอมรับรถ</strong> ตามกำหนด</td>
                               <td >&nbsp;</td>
                            </tr>
                            <tr>
                               <td>&nbsp;</td>
                               <td>2.จองแล้ว<strong>ไม่จัดไฟแนนซ์</strong>และไม่ให้ความร่วมมือในการจัดไฟแนนท์</td>
                               <td style={{width: '300px'}}>
                                 <div className="form-inline mt-2">
                                   <span style={{width: 100}} >ค่าตรวจสอบ</span>
                                   <NumberFormat
                                     className="form-control ml-2"
                                     style={styles.inputShort}
                                     thousandSeparator={true}
                                     prefix={''}
                                     placeholder=""
                                     min={0}
                                     max={1000000000}
                                     step={1000}
                                     size={10}
                                     allowNegative={false}
                                     value={this.state.check_fee === 0 ? '' : this.state.check_fee}
                                     onValueChange={(values) => {
                                       const {value} = values
                                       this.setState({check_fee: value})
                                     }}
                                   />
                                   <span style={{width: 30}} className="ml-2" >บาท</span>
                                 </div>

                               </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <u>ความรับผิดชอบของผู้ขายดังนี้</u> <br/>
                        1.รับซื้อรถคืน 100% <br/>
                        <table>
                          <tbody>
                            <tr><td style={{width: 30}}>&nbsp;</td><td>1.1 รถชนหนัก พลิกคว่ำ หรือน้ำท่วม</td></tr>
                            <tr><td style={{width: 30}}>&nbsp;</td><td>1.2 ไม่สามารถโอนเล่มทะเบียน เป็นกรรมสิทธิ์ของผู้ซื้อได้</td></tr>
                            <tr><td style={{width: 30}}>&nbsp;</td><td>1.3 ลูกค้าพร้อมรับรถ...................................................</td></tr>
                            <tr><td style={{width: 30}}>&nbsp;</td><td>1.4 รถตีเทิร์น ยี่ห้อ........................................ รุ่น...............................ทะเบียน...............................ปี................. เลขไมล์.....................ราคา......................</td></tr>
                            <tr><td style={{width: 30}}>&nbsp;</td><td>(1.1-1.2 รับซื้อภายใน 7 วัน ตั้งแต่วันที่รับรถไป)</td></tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        2.รับประกัน เครื่องยนต์ ช่วงล่าง ระบบแอร์ (ฟรีค่าแรงค่าอะไหล่) ภายในเวลา 1 ปี หรือ 10,000 กิโลเมตร แล้วแต่ระยะไหนถึงก่อนนับแต่วันที่ออกรถไป
                        และเป็นไปตามปกติของการใช้งานทั่วไป (ต้องนำรถยนต์มารับบริการที่ ศูนย์บริการ บริษัท เอ.ที.คาร์เซลส์ จำกัด เท่านั้น)
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        3.รับประกัน เกียร์ เฟื่องท้าย (ฟรีค่าแรงค่าอะไหล่) ภายในเวลา 1 ปี หรือ 10,000 กิโลเมตร และรถที่มีอายุ เกิน 7 ปี ขึ้นไป
                        รับประกัน 6 เดือน หรือ 5,000 กิโลเมตรแล้วแต่ระยะไหนถึงก่อนนับแต่วันที่ออกรถไป และเป็นไปตามปกติของการใช้งานทั่วไป
                        (ต้องนำรถยนต์มารับบริการที่ ศูนย์บริการ บริษัท เอ.ที.คาร์เซลส์ จำกัด เท่านั้น)
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        ** รถที่มีอายุ เกิน 10 ปี ไม่มีรับประกันหลังการขาย**
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        4. เงื่อนไขการรับประกัน เป็นไปตามบันทึกข้อตกลงเงื่อนไขการรับประกันของบริษัทเท่านั้น
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <table style={styles.table}>
                            <tbody>
                              <tr className="mt-3">
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  ลงชื่อ.............................................................................(ผู้ขาย)
                                </td>
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  ลงชื่อ.............................................................................(ผู้ซื้อ)
                                </td>
                              </tr>
                              <tr >
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                 <div className="mt-0"> เบอร์โทรศัพท์.................................................................(ผู้ขาย) </div>
                                </td>
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  <div className="mt-0"> เบอร์โทรศัพท์.................................................................(ผู้ซื้อ) </div>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-1">

                          ข้าพเจ้าผู้ซื้อ..............{this.state.buyer_name === '' ? '........................................' : this.state.buyer_name}...........

                          ได้รับรถยนต์หมายเลขทะเบียน...........{this.state.car_license_plate+'....'+this.state.province_name}...........
                          เลขไมล์..........{this.state.car_miles}..............
                          ไว้ในความครอบครองเมื่อวันที่......................................................................................
                          ได้ตรวจสอบเลขไมล์ เลขเครื่องยนต์ และเลขตัวถัง  ตามจริงและยินยอมรับรถ ตามสภาพ ทุกประการ หากเกิดการผิดพลาดข้อมูลไม่ตรง จะไม่เรียกร้องค่าเสียหายใด ๆ จากบริษัท ในภายหลัง
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-0">
                          <span style={{width: 90, textAlign: 'left'}} > หมายเหตุ </span>
                          <input className="form-control"
                                 style={{width: '80%'}}
                                 name="note"
                                 value={this.state.note}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <table style={styles.table}>
                            <tbody>
                            <tr className="mt-3">
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงชื่อ.............................................................................(ผู้ขาย)
                              </td>
                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                ลงชื่อ.............................................................................(ผู้รับรถ)
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-1">
                         &nbsp;
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={{width: '100%'}} colSpan={3}>
                        <div className="p-2" style={{border: '1px solid #000', width: '100%', minHeight: 150}} >
                          <table style={styles.table}>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                     <span style={{width: 200, textAlign: 'left'}} >เบอร์โทรศัพท์สำหรับติดต่อประกัน</span>
                                     <input className="form-control ml-2"
                                            style={styles.inputLong}
                                            name="insurance_mobile"
                                            value={this.state.insurance_mobile}
                                            onChange={this.handleOnChange}
                                     />
                                    <span style={{width: 100, textAlign: 'right', }} >เลขที่รับแจ้ง</span>
                                    <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           name="insurance_received_number"
                                           value={this.state.insurance_received_number}
                                           onChange={this.handleOnChange}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span style={{width: 250, textAlign: 'left', borderRadius: '5px'}} >
                                      เบอร์โทรศัพท์สำหรับการติดต่อตามเอกสาร
                                    </span>
                                    <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           name="document_contact_mobile"
                                           value={this.state.document_contact_mobile}
                                           onChange={this.handleOnChange}
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span>
                                      หมายเหตุ (1.)สัญญาเช่าซื้อ (2.)สำเนาทะเบียนรถ (3.)เอกสารประกันชั้น (4.)เอกสารประกันชั้น1 (5.)เอกสารประกันอุบัติเหตุส่วน
                                    </span>
                                  </div>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    {/*<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>*/}

                  </tbody>
                </table>
              </div>

              <div className="col-md-12 text-right">
                <div className="card-block">

                  {
                    this.state.contract_id === null ? (
                      <button type="button"
                              className="btn btn-success btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.saveCarContract('add')}
                      >
                        <i className="icon-check"/>
                        &nbsp;บันทึก
                      </button>
                    ) : <button type="button"
                                className="btn btn-warning btn-md mr-2"
                                style={{width: 120}}
                                onClick={() => this.saveCarContract('edit')}
                    >
                      <i className="icon-pencil"/>
                      &nbsp;แก้ไข
                    </button>
                  }

                  {
                    this.state.contract_id === null ? null : (
                      <button type="button"
                              className="btn btn-primary btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.printCarContract()}
                      >
                        <i className="icon-printer"/>  พิมพ์
                      </button>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}


const styles = {
  table: {
    width: '100%',
    maxWidth: '100%'
  },
  tdContentCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tdContentRight: {
    textAlign: 'right',
    verticalAlign: 'middle',
    justifyContent: 'right',
  },
  tdContentLeft: {
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  tableTh:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tableTdLeft:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  tableTdCenter:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingLeft: '70px',
  },
  tableTdRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
  },
  tablePd:{
    paddingLeft: '50px',
    paddingRight: '50px',
    lineHeight: '2.2',
  },
  amountsText:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  amountsTextNoBorder:{
    maxWidth: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  amountsRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
    fontWeight: 'bold',
  },
  buttonHeight: {
    height: 32,
  },
  inputData:{
    borderRadius: '5px',
    width: '450px',
  },
  inputDataNum:{
    borderRadius: '5px',
    width: '100px'
  },
  inputDataWriteAt:{
    borderRadius: '5px',
    width: '180px'
  },
  inputShort: {
    borderRadius: '5px',
    width: '120px'
  },
  inputLong: {
    borderRadius: '5px',
    width: '220px'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarSalesContractAt)

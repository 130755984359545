import React, { Component } from 'react';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
moment.locale('th');

class ExpenseForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            expense:'',
            branch:[],
            expense_type:[],
            income_expense_item:[],
            expense_amount:0,
            startDateExpense:  new Date(), // moment().format('YYYY-MM-DD'),
            expense_name:'',
            expense_image:'',
            user: {},
            w_expense_image_file: false,
            //selectedItem:'',

        }
        this.handleDateExpense = this.handleDateExpense.bind(this);
        this.convertBBtoCC = this.convertBBtoCC.bind(this);
        this.onMaskedDateExpense = this.onMaskedDateExpense.bind(this);
        this.handleExpenseNameChange = this.handleExpenseNameChange.bind(this);
        this.handleOnSubmitExpense = this.handleOnSubmitExpense.bind(this);
    }

    handleOnSubmitExpense(event) {
        event.preventDefault()
        let that = this ;
        // let expense_date = this.state.startDateExpense
        let expense_date =  event.target.expense_date_text.value
        let branch_id = event.target.branch_id.value
        let income_expense_item_id = event.target.income_expense_item_id.value
        let expense_name = event.target.expense_name.value
        let expense_amount = event.target.expense_amount.value
        // let expense_image_file = event.target.expense_image_file.value
        let expense_type_id = 2

        if(expense_amount===''){
            alert('กรุณากรอกจำนวนเงิน')
            this.refs.expense_amount.focus()
            return ;
        }

        if(income_expense_item_id===''){
            alert('กรุณาเลือกหมวดหมู่ค่าใช้จ่าย')
            this.refs.income_expense_item_id.focus()
            return ;
        }

        if(branch_id===''){
            alert('กรุณาเลือกสาขา')
            this.refs.branch_id.focus()
            return ;
        }

      /* try {
        if(event.target.expense_image_file.files[0].name === undefined){
          this.setState({
            w_expense_image_file: false
          });
        }
      }catch (ex){
        this.setState({
          w_expense_image_file: true
        });
        return;
      } */

      if(event.target.expense_image_file.files.length === 0) { // nofile
        var data = {
          expense_date: expense_date,
          branch_id: branch_id,
          income_expense_item_id: income_expense_item_id,
          expense_name: expense_name,
          expense_amount: expense_amount,
          expense_type_id: expense_type_id,
          user_created: this.state.user.user,
          user_updated: this.state.user.user,
          expense_image_file: 'nophoto.png'
        }
        axios.post(APIURL + '/expense/nofile/', data)
          .then(function (response) {
            that.props.onToggle();

          })
          .catch(function (error) {
            console.log('error:', error)
            //alert("error"+error)
          })
      } else {

        var filename = event.target.expense_image_file.files[0].name;
        //alert (filename)
        //return;

        if (!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)) {
          //alert(event.target.car_repair_file.files[0].type);
          this.setState({
            w_expense_image_file: true
          });
          return;
        }

        const data = new FormData();
        data.append('file', event.target.expense_image_file.files[0], event.target.expense_image_file.files[0].name);
        data.append('expense_date', expense_date);
        data.append('branch_id', branch_id);
        data.append('income_expense_item_id', income_expense_item_id);
        data.append('expense_name', expense_name);
        data.append('expense_amount', expense_amount);
        data.append('expense_type_id', expense_type_id);
        data.append('user_created', this.state.user.user);
        data.append('user_updated', this.state.user.user);

        axios.post(APIURL + '/expense/s3', data)
          .then(function (response) {
            that.props.onToggle();
          })
          .catch(function (error) {
            console.log('error: ', error)
            //alert("error"+error)
        })
      }
    }


    handleDateExpense(date) {
        this.setState({
            startDateExpense: new Date(date)
        });
    }

    onMaskedDateExpense(e){
        let buddhist_date = e.target.value ;
        //alert('buddhist_date='+buddhist_date);
        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.handleDateExpense(cc_date)
        }
    }

    convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
        let date_arr=buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = date_arr[2]-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    /*formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }*/

    /*makeIncomeExpenseItemName() {
        var item = this.state.tmp_income_expense_item

        var expense_name = ''

        if (item != '') {
            if (item.includes(item)) {
                expense_name = item
            }
        }

        this.setState({
            expense_name: expense_name
        });
    }*/

    handleIncomeExpenseItemChange (event) {
        // let income_expense_item_id = event.target.value
        let income_expense_item_name = event.target.options[event.target.selectedIndex].text
        let that = this ;
        // alert (income_expense_item_name)
        // console.log('income_expense_item_id=', income_expense_item_id, typeof income_expense_item_id)
        that.setState({
          expense_name: income_expense_item_name,
          // income_expense_item_id: income_expense_item_id
        })
    }

    handleExpenseNameChange(){
        let expense_name = this.refs.expense_name.value
        this.setState({
            expense_name : expense_name
        });
    }

    loadData () {
        axios.get(APIURL + '/expense/')
            .then(res => {
                //alert("res"+res);
                this.setState({lists: res.data, })
            }).catch(error => {
            //alert("error"+error.message);
            this.setState({lists: []})
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branch: res.data})
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    loadExpenseTypeData () {
        axios.get(APIURL + '/expense_type/')
            .then(res => {
                this.setState({expense_type: res.data})
            }).catch(error => {
            this.setState({expense_type: []})
        })
    }

    loadIncomeExpenseItemData () {
        axios.get(APIURL + '/income_expense_item/get/expense')
            .then(res => {
                this.setState({income_expense_item: res.data})
            }).catch(error => {
            this.setState({income_expense_item: []})
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadBranchData()
        this.loadExpenseTypeData()
        this.loadIncomeExpenseItemData()
    }

    /*handleOnChange = (selectedItem) => {
            this.setState({ selectedItem });
    }*/

    componentWillMount () {
        this.setState({
            user: JSON.parse(this.props.user)
        });
    }

    render(){
        //const { history,user } = this.props

        let branch_list = this.state.branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

      let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
            return(
                <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
            )
        })


        return (
            <div className="card card-accent-primary">
                <form action="" method="post" onSubmit={this.handleOnSubmitExpense.bind(this)}>
                    <div className="card-header">
                        <strong className="text-title">เพิ่มข้อมูลค่าใช้จ่าย</strong>
                    </div>

                    <div className="card-block">
                        <div className="row">

                            <div className="form-group col-sm-6">
                                <label htmlFor="expense_date_text">วันที่ใช้จ่าย</label>
                                {/*<MaskedInput
                                    mask="11-11-1111"
                                    name="expense_date_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateExpense}
                                    className="form-control"
                                />*/}
                                <br/>
                                <DatePicker
                                    type="text"
                                    className="form-control"
                                    id="expense_date_text"
                                    name="expense_date_text"
                                    placeholder=""
                                    selected={this.state.startDateExpense}
                                    onChange={this.handleDateExpense}
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        ref="branch_id"
                                >
                                    {branch_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="income_expense_item">หมวดค่าใช้จ่าย</label>
                                <select
                                    className="form-control"
                                    id="income_expense_item_id"
                                    ref="income_expense_item_id"
                                    onChange={this.handleIncomeExpenseItemChange.bind(this)}
                                    //name="form-field-name"
                                    //value={this.state.selectedItem}
                                    //onChange={this.handleOnChange}
                                    //options=
                                >
                                    {income_expense_item_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="expense_name">รายละเอียดค่าใช้จ่าย (พิมพ์เพิ่มเติมได้)</label>
                                <input type="text"
                                       className="form-control"
                                       id="expense_name"
                                       ref="expense_name"
                                       value={this.state.expense_name}
                                       placeholder=""
                                       onChange={this.handleExpenseNameChange}
                                />
                            </div>

                            <div className="form-group col-sm-12">
                                <label htmlFor="expense_amount">ยอดค่าใช้จ่าย</label>
                                <input
                                    type="text"
                                    className="form-control hidden"
                                    name="expense_amount"
                                    placeholder=""
                                    value={this.state.expense_amount}
                                    onChange={() => {}}
                                />
                                <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="ค่าใช้จ่าย"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({expense_amount: value})
                                    }}
                                />
                            </div>

                            <div className="form-group col-md-12">
                                <label htmlFor="expense_image_file">เอกสารแนบ </label>
                                <input type="file"
                                       id="expense_image_file"
                                       name="expense_image_file"
                                       accept="image/*"
                                       className="form-control"
                                       onChange={(e)=>{
                                           const { target } = e
                                           if(target.value.length > 0){
                                               this.setState({
                                                   w_expense_image_file: false
                                               });
                                           } else {
                                               //target.reset();
                                               this.setState({
                                                   w_expense_image_file: true
                                               });
                                           }

                                       }}
                                />
                                { this.state.w_expense_image_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

                            </div>

                        </div>
                    </div>

                    <div className="card-footer text-right">
                        <button type="reset"
                                onClick={this.props.onToggle}
                                className="btn btn-sm btn-danger mr-2">
                            <i className="fa fa-refresh"/> ยกเลิก
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-primary">
                            <i className="fa fa-save"/> บันทึก
                        </button>
                        {/*<input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>*/}
                    </div>

                </form>
            </div>
        )

    }
}

/*const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    }
}*/

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseForm);

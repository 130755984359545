import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import CarInReport from './CarInReport'
import CarInBuyReport from './CarInBuyReport'

class CarInReportTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      activeTab: '1',
    }

    this.toggle = this.toggle.bind(this);
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user),
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
    return(
      <div className="col-md-12">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              <i className="icon-options-vertical"/> รายงานสต๊อกรถ
            </NavLink>
          </NavItem>
          {/*<NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              <i className="icon-options-vertical"/> รายงานการซื้อรถ
            </NavLink>
          </NavItem>*/}
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <CarInReport />
          </TabPane>
          <TabPane tabId="2">
            <CarInBuyReport />
          </TabPane>
        </TabContent>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarInReportTabs);

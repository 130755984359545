import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import { IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../../config/config';
import PreviewImage from '../../../views/PreviewImage/';
import {  Modal, ModalBody } from 'reactstrap';

class ExpenseListRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            preview_image_url: '',
            preview_image_select: false
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
        this.saveCarImage = this.saveCarImage.bind(this)
    }

  componentWillMount () {
    //const queryString = require('query-string');
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

  saveCarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;
  }

    render(){
      const emptyList = <tr><td colSpan={9} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>
      const expenseListData = this.props.expense_list.map((expense,index)=>{

        let image_url = '';

        if (expense.expense_image_file.includes('https')) {
          image_url = expense.expense_image_file
        } else {
          if (expense.expense_image_file==='nophoto.png') {
            image_url = IMAGE_NOPHOTO_URL
          } else {
            image_url =  IMAGE_FULL_URL+expense.expense_image_file
          }
        }

            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center">{moment(expense.expense_date).locale('th').format('LL')}</td>
                    <td className="text-center">{expense.branch_name}</td>
                    <td className="text-center">{expense.income_expense_item_name}</td>
                    <td className="text-center">{expense.expense_name}</td>
                    <td className="text-center">

                      {
                        image_url===IMAGE_NOPHOTO_URL ? null : <>
                          <a rel="noopener noreferrer" href={image_url}
                             target="_blank"
                             onClick={(e)=>this.saveCarImage(e, image_url)}
                             className={image_url===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                            <span>download</span>
                          </a>
                          <br/>
                          <br/>
                        </>
                      }

                        <img
                          src={image_url}
                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร"
                            onClick={()=>this.onPreviewSelected(image_url)}
                        />

                    </td>
                    <td className="text-center">
                        <NumberFormat value={expense.expense_amount}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'฿'}/>
                    </td>
                    <td className="text-center">
                        {expense.fullname}
                        <br/>
                        {moment(expense.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className={this.state.user.type==='admin' || this.state.user.type==='manager' ? "text-center" : "hidden" }>

                      <button
                        type="button"
                        className="btn btn-warning btn-sm btn-block"
                        style={{width: 90}}
                        onClick={() => this.props.onEditExpenseModal(expense)}
                      >
                        <i className="icon-pencil">&nbsp;แก้ไข</i>
                      </button>

                      <button
                        type="button"
                              className="btn btn-success btn-sm btn-block mt-2"
                              style={{width: 90}}
                              onClick={() => this.props.onSaveFileModal(expense)}
                      >
                        <i className="icon-doc">&nbsp;แนบไฟล์</i>
                      </button>

                      <button className="btn btn-danger btn-sm btn-block mt-2"
                              style={{width: 90}}
                              onClick={() => this.props.onDeleteHandle(expense)}
                      >
                        <i className="fa fa-remove"></i> ลบ
                      </button>

                    </td>
                </tr>
            )
        });

      const expenseList = this.props.expense_list.length === 0 ? emptyList : expenseListData

        return (
            <div className="card-block">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">วันที่ใช้จ่าย</th>
                        <th className="text-center">สาขา</th>
                        <th className="text-center">หมวดค่าใช้จ่าย</th>
                        <th className="text-center">รายละเอียด</th>
                        <th className="text-center">ไฟล์เอกสาร</th>
                        <th className="text-center">ยอดจ่าย</th>
                        <th className="text-center">ผู้บันทึก</th>
                        <th className={this.state.user.type==='admin' || this.state.user.type==='manager' ? "text-center" : "hidden" }>
                         ลบ
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {expenseList}
                    </tbody>
                </table>

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>
                {/*<ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                    <li className="page-item active">
                        <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseListRow)

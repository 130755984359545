import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../config/config'
import NumberFormat from 'react-number-format';


const port = Number(window.env.PORT);


class SalesPriceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      car_sell_id: this.props.car_sell.car_sell_id,
      customer_name : this.props.car_sell.customer_name,
      vat_sell: this.props.car_sell.vat_sell, // vat ขาย รายรับ
      finance_commission: this.props.car_sell.finance_commission,  // ค่าคอมมิชชั่นไฟแนนท์ รายรับ
      vat_commission: this.props.car_sell.vat_commission,  // vat คอมมิชชั่น รายรับ
      // รายจ่ายเพิ่ม
      car_external_commission: this.props.car_sell.car_external_commission, // ค่านายหน้า ภายนอก
      sales_commission: this.props.car_sell.sales_commission, // ค่าคอมมิชชั่นฝ่ายขาย
      additional_commission: this.props.car_sell.additional_commission, // ค่าคอมมิชชั่นเพิ่มเติม
      advertising_cost: this.props.car_sell.advertising_cost, // ค่าโฆษณา
      delivery_fee: this.props.car_sell.delivery_fee, // ค่าบริการส่งรถ
      promotion_fee: this.props.car_sell.promotion_fee, // ค่าโปรโมชั่น
      finance_fee: this.props.car_sell.finance_fee, // ค่าธรรมเนียมไฟแนนท์
      transfer_fee: this.props.car_sell.transfer_fee, // ค่าโอนรถ
      cost_phra: this.props.car_sell.cost_phra, // พระ(เงินกองเงินที่บริษัทหักออกรายคัน )
      cost_vat_and_property_tax: this.props.car_sell.cost_vat_and_property_tax, // ภาษีมูลค่าเพิ่ม + ภาษีโรงเรือน
    };

    this.handleOnSubmitCarSellPriceEdit = this.handleOnSubmitCarSellPriceEdit.bind(this);
  }



  handleOnSubmitCarSellPriceEdit(event) {
    event.preventDefault()

    let car_sell_id = this.state.car_sell_id
    // รายรับ
    let vat_sell = event.target.vat_sell.value
    let finance_commission = event.target.finance_commission.value
    let vat_commission = event.target.vat_commission.value
    // รายจ่าย
    let car_external_commission = event.target.car_external_commission.value
    let sales_commission = event.target.sales_commission.value
    let additional_commission = event.target.additional_commission.value
    let advertising_cost = event.target.advertising_cost.value
    let delivery_fee = event.target.delivery_fee.value
    let promotion_fee = event.target.promotion_fee.value
    let finance_fee = event.target.finance_fee.value
    let transfer_fee = event.target.transfer_fee.value

    let cost_phra = this.state.cost_phra
    let cost_vat_and_property_tax = this.state.cost_vat_and_property_tax

    // console.log('car_sell_id=', car_sell_id)
    // console.log('----- รายรับ')
    // console.log('vat_sell=', vat_sell)
    // console.log('finance_commission=', finance_commission)
    // console.log('----- รายจ่าย')
    // console.log('car_external_commission=', car_external_commission)
    // console.log('sales_commission=', sales_commission)
    // console.log('additional_commission=', additional_commission)
    // console.log('advertising_cost=', advertising_cost)
    // console.log('delivery_fee=', delivery_fee)
    // console.log('finance_fee=', finance_fee)
    // console.log('transfer_fee=', transfer_fee)

    let data = {
      car_sell_id: car_sell_id,
      vat_sell: vat_sell,
      finance_commission: finance_commission,
      car_external_commission: car_external_commission,
      sales_commission: sales_commission,
      additional_commission: additional_commission,
      advertising_cost: advertising_cost,
      delivery_fee: delivery_fee,
      promotion_fee: promotion_fee,
      finance_fee: finance_fee,
      transfer_fee: transfer_fee,
      vat_commission: vat_commission,
      cost_phra: cost_phra,
      cost_vat_and_property_tax: cost_vat_and_property_tax,
    }

    axios.put(APIURL + '/car_sell/car_sell_edit_price/'+car_sell_id, data)
      .then(response => {
        if(response.status === 200) {
          console.log(response.data)
          this.props.onToggle()
          this.props.onLoadCarSellById()
        }
      })
      .catch(error => {
        alert("พบข้อผิดพลาด : "+error)
      })
  }

  render(){
    return (
      <form action="" method="post" onSubmit={this.handleOnSubmitCarSellPriceEdit}>
        <div className="row" >
          <div className="col-sm-12">
            <div className="card card-accent-warning">
              <div className="card-header card-sparepart">
                <strong className="text-title">แก้ไขรับ-จ่ายการขาย</strong>
              </div>

              <div className="card-block">

                <div className="row" style={styles.bottomLineAndMargin}>
                  <div className="form-group col-sm-2">
                    <h6>ค่าใช้จ่ายการขาย</h6>
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="car_external_commission">ค่านายหน้า</label>
                    <input type="text"
                           className="form-control hidden"
                           id="car_external_commission"
                           name="car_external_commission"
                           placeholder=""
                           ref="car_external_commission"
                           value={this.state.car_external_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_external_commission}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          car_external_commission: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="sales_commission">ค่าคอมมิชชั่นฝ่ายขาย</label>
                    <input type="text"
                           className="form-control hidden"
                           id="sales_commission"
                           name="sales_commission"
                           placeholder=""
                           ref="sales_commission"
                           value={this.state.sales_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.sales_commission}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          sales_commission: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="additional_commission">ค่าคอมมิชชั่นเพิ่มเติม</label>
                    <input type="text"
                           className="form-control hidden"
                           id="additional_commission"
                           name="additional_commission"
                           placeholder=""
                           ref="additional_commission"
                           value={this.state.additional_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.additional_commission}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          additional_commission: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-2">
                    &nbsp;
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="additional_commission">ค่าโฆษณา</label>
                    <input type="text"
                           className="form-control hidden"
                           id="advertising_cost"
                           name="advertising_cost"
                           placeholder=""
                           ref="advertising_cost"
                           value={this.state.advertising_cost}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.advertising_cost}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          advertising_cost: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="delivery_fee">ค่าบริการส่งรถ</label>
                    <input type="text"
                           className="form-control hidden"
                           id="delivery_fee"
                           name="delivery_fee"
                           placeholder=""
                           ref="delivery_fee"
                           value={this.state.delivery_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.delivery_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          delivery_fee: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="promotion_fee">ค่าโปรโมชั่น</label>
                    <input type="text"
                           className="form-control hidden"
                           id="promotion_fee"
                           name="promotion_fee"
                           placeholder=""
                           ref="promotion_fee"
                           value={this.state.promotion_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.promotion_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          promotion_fee: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-2">
                    &nbsp;
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="finance_fee">ค่าธรรมเนียมไฟแนนท์</label>
                    <input type="text"
                           className="form-control hidden"
                           id="finance_fee"
                           name="finance_fee"
                           placeholder=""
                           ref="finance_fee"
                           value={this.state.finance_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.finance_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          finance_fee: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="transfer_fee">ค่าโอนรถ</label>
                    <input type="text"
                           className="form-control hidden"
                           id="transfer_fee"
                           name="transfer_fee"
                           placeholder=""
                           ref="transfer_fee"
                           value={this.state.transfer_fee}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.transfer_fee}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          transfer_fee: value
                        })
                      }}
                    />
                  </div>

                  {
                    port === 9043 ? <>

                      <div className="form-group col-sm-3">
                        <label htmlFor="cost_phra">พระ</label>
                        <input type="text"
                               className="form-control hidden"
                               id="cost_phra"
                               name="cost_phra"
                               placeholder=""
                               value={this.state.cost_phra}
                               onChange={()=>{}}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.cost_phra}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({
                              cost_phra: value
                            })
                          }}
                        />
                      </div>

                      <div className="form-group col-sm-2">
                        &nbsp;
                      </div>

                      <div className="form-group col-sm-3">
                        <label htmlFor="cost_vat_and_property_tax" style={{fontSize: 16}} >ภาษีมูลค่าเพิ่ม + ภาษีโรงเรือน</label>
                        <input type="text"
                               className="form-control hidden"
                               id="cost_vat_and_property_tax"
                               name="cost_vat_and_property_tax"
                               placeholder=""
                               value={this.state.cost_vat_and_property_tax}
                               onChange={()=>{}}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.cost_vat_and_property_tax}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({
                              cost_vat_and_property_tax: value
                            })
                          }}
                        />
                      </div>

                    </> : null
                  }


                </div>

                <div className="row" style={styles.bottomLineAndMargin}>

                  <div className="form-group col-sm-2">
                    <h6>รายรับการขาย</h6>
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="cost_finance">vat ยอดจัดได้คืน</label>
                    <input type="text"
                           className="form-control hidden"
                           id="vat_sell"
                           name="vat_sell"
                           placeholder=""
                           ref="vat_sell"
                           value={this.state.vat_sell}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.vat_sell}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          vat_sell: value
                        })
                      }}
                    />
                  </div>


                  <div className="form-group col-sm-3">
                    <label htmlFor="finance_commission">ค่าคอมมิชชั่นไฟแนนท์</label>
                    <input type="text"
                           className="form-control hidden"
                           id="finance_commission"
                           name="finance_commission"
                           placeholder=""
                           ref="finance_commission"
                           value={this.state.finance_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.finance_commission}
                      onValueChange={(values) => {
                        const {value} = values
                        this.setState({
                          finance_commission: value
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-3">
                    <label htmlFor="vat_commission">VAT คอมมิชชั่น</label>
                    <input type="text"
                           className="form-control hidden"
                           id="vat_commission"
                           name="vat_commission"
                           placeholder=""
                           ref="vat_commission"
                           value={this.state.vat_commission}
                           onChange={()=>{}}
                    />
                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.vat_commission}
                        onValueChange={(values) => {
                          const {value} = values
                          this.setState({
                            vat_commission: value
                          })
                        }}
                    />
                  </div>


                </div>

              </div>

              <div className="card-footer text-right">
                <button type="reset"
                        className="btn btn-sm btn-danger mr-2"
                        onClick={this.props.onToggle}
                >
                  <i className="fa fa-refresh"/> ยกเลิก
                </button>
                <button type="submit"
                        className="btn btn-sm btn-warning">
                  <i className="fa fa-save"/> แก้ไขข้อมูล
                </button>
              </div>

            </div>
          </div>
        </div>
      </form>
    )
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  bottomLineAndMargin: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    paddingTop: 8
  }
}

export default SalesPriceEdit;

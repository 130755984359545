import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../../config/config'
import axios from 'axios'
import Loading from '../../Loading'
import { Modal, ModalBody } from 'reactstrap'
import PreviewImage from '../../../views/PreviewImage'
import { Link } from 'react-router-dom'

class ExpenseRepair extends Component{
  constructor (props) {
    super(props)
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      expenseCarRepairList: [],
      sumCarRepair: 0,
      isLoading: false,
      preview_image_url: '',
      preview_image_select: false
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd   = this.handleDateEnd.bind(this)
    this.loadExpenseCarRepair   = this.loadExpenseCarRepair.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
  }

  componentDidMount() {
    this.loadExpenseCarRepair()
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    }, () =>  this.loadExpenseCarRepair())
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    }, () => this.loadExpenseCarRepair())
  }

  loadExpenseCarRepair() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
    let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let url = APIURL+'/expense/car_repair/'+date_start+'/'+date_end

    this.setState({
      isLoading: true
    }, () => {

      axios.get(url).then(res => {
        if(res.status === 200){
          // console.log(res.data)
          // car_repair_cost | car_cost_repair
          let sumCarRepair = 0
          if(res.data.length > 0) {
            sumCarRepair =  res.data.reduce((acc, next) => acc + next.car_repair_cost, 0)
          }
          // console.log('sumCarRepair', sumCarRepair)
          this.setState({
            expenseCarRepairList: res.data,
            sumCarRepair: sumCarRepair,
            isLoading: false
          })
        }
      })

    })
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  render () {
    const { expenseCarRepairList } = this.state

    const emptyList = <tr><td colSpan={8} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>
    const expenseCarRepairTR = expenseCarRepairList.map((expense, index) => {

      let image_url = ''
      if( expense.car_repair_file === 'nophoto.png') {
        image_url = IMAGE_NOPHOTO_URL
      } else {
        // check https
        if (expense.car_repair_file  !== null) {
          if (expense.car_repair_file .includes('https')) {
            image_url = expense.car_repair_file
          }else{
            image_url = IMAGE_FULL_URL + expense.car_repair_file
          }
        }else{
          image_url = IMAGE_NOPHOTO_URL
        }
      }

      return (
        <tr key={index}>
          <th className="text-center">{index + 1}</th>
          <th className="text-center" width={120}>
            {moment(expense.car_repair_send).format('YYYY-MM-DD')}</th>
          <th className="text-left">
            {expense.car_repair_name}
            <br/>
            อู่: {expense.center_name}
          </th>
          <th className="text-left" width={200} >
            {expense.car_name}<br/>
            ทะเบียน: {expense.car_license_plate_new}
          </th>
          <th className="text-center">
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              alt="เอกสาร"
              onClick={()=>this.onPreviewSelected(image_url)}
            />
          </th>
          <th className="text-center">
            <NumberFormat value={expense.car_repair_cost}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'} />
          </th>
          <th className="text-center" width={150}>
            {expense.user_created} <br/>
            เมื่อ {moment(expense.car_repair_created_at).format('DD-MM-YYYY HH:mm:ss')}
          </th>
        </tr>
      )
    })

    const expenseBody = expenseCarRepairList.length === 0 ? emptyList : expenseCarRepairTR

    return (
      <div className="card">

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-info">
                <div className="card-header">
                  <strong className="text-title"> ข้อมูลค่าใช้จ่ายซ่อมรถ </strong>
                  {/*<button type="button"
                          onClick={this.toggleExpense}
                          className="btn btn-primary float-right">
                    <i className="icon-plus"></i>&nbsp; เพิ่มข้อมูลค่าใช้จ่าย
                  </button>
                  <Modal isOpen={this.state.toggle_expense} toggle={this.toggleExpense}
                         className={'modal-lg'}>
                    <ModalBody>
                      <ExpenseForm onToggle={this.toggleExpense}/>
                    </ModalBody>
                  </Modal>*/}

                  <Link to={'/report/carrepairbycenter'} target='_blank'>
                    <button className="btn btn-success float-right">
                      <i className="icon-briefcase"/>&nbsp; สรุปค่าซ่อมตามอู่
                    </button>
                  </Link>

                  <Link to={'/report/carrepair'} target='_blank'>
                    <button className="btn btn-success float-right mr-2">
                      <i className="icon-briefcase"/>&nbsp; สรุปค่าซ่อมรายคัน
                    </button>
                  </Link>

                  <Link to={'/report/carrepairbyretailer'} target='_blank'>
                    <button className="btn btn-success float-right mr-2">
                      <i className="icon-briefcase"/>&nbsp; สรุปค่าซ่อมตามร้านอะไหล่
                    </button>
                  </Link>

                </div>

                <div className="row mt-3 mb-4">
                  <div className="col-md-1 text-right mt-1">
                    จาก
                  </div>
                  <div className="row col-6">
                    <div className="col-4">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-1 mt-1 ml-3">
                      ถึง
                    </div>

                    <div className="col-4">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""

                      />
                    </div>
                  </div>
                  {/*<div className="col-2 text-right pt-1">
                    <Link to={'/report/carrepair'} target='_blank' type="button"
                          className="btn btn-success">
                      <i className="icon-paper-clip">
                        &nbsp;ค่าซ่อมรายคัน
                      </i>
                    </Link>
                  </div>*/}
                  <div className="col-5 text-right pt-2 pb-1">
                    <h6>
                      รวมค่าซ่อมรถ&nbsp;
                      <NumberFormat
                        displayType={'text'}
                        thousandSeparator={true}
                        value={this.state.sumCarRepair.toFixed(0)}/>
                      &nbsp;บาท
                    </h6>
                  </div>
                </div>

                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">วันที่ส่งซ่อม</th>
                    <th className="text-center">รายละเอียด</th>
                    <th className="text-center">ข้อมูลรถ</th>
                    <th className="text-center">ไฟล์เอกสาร</th>
                    <th className="text-center">ยอดจ่าย</th>
                    <th className="text-center">ผู้บันทึก</th>
                  </tr>
                  </thead>
                  <tbody>
                   {expenseBody}
                  </tbody>
                </table>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseRepair)
